import axios from 'axios'
const ENV = process.env;

const production = "https://rapi.insites-consulting.com";
// const local = "http://localhost:8000";
const local = "https://rapi.insites-consulting.com";
const test = "https://rapi.insites-consulting.com"
let url = local;

let token = "";
if(localStorage.getItem('token')!==null){
  token = localStorage.getItem('token') || "0";
}

switch (ENV.NODE_ENV) {
  case "production": url = production;break;
  case "development": url = local;break;
  case "test": url = test;break;
  default: url = local;
}
export default axios.create({
  baseURL:url,  
  headers: {
    'Authorization': `JWE ${token}`,
   "Content-type": "text/plain,application/json",
 //  "Access-Control-Allow-Origin" : "*",
  }
}); 

export const post = (endpoint:string, body: any) => axios.post(`${url}${endpoint}`, body)
