<template>
    <v-alert
      :value="showmodal"
      dismissible
      color="white"
      dark
      border="top"
      icon="mdi-home"
      transition="scale-transition"
      type="success"
    >
   
     <isc-raw-data-export></isc-raw-data-export>
    </v-alert>
</template>

<script>
export default {

    props:{
        showmodal:{
            type: Boolean
        }
    },
  

}
</script>

<style>

</style>