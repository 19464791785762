import DropDownItem from "@/types/DropDownItem";

export enum TablesMutations {
    SET_COUNTS_TABLE ='SET_COUNTS_TABLE',
    SET_KPIS_TABLE ='SET_KPIS_TABLE',
    SET_ACTVITIES_TABLE = 'SET_ACTVITIES_TABLE',
    SET_QUAL_ACTIVITIES = 'SET_QUAL_ACTIVITIES'
}

export enum TablesActions{

    FETCH_ACTIVITTIES_TABLE ='FETCH_ACTIVITTIES_TABLE',
    FETCH_QUAL_ACTIVITTIES_TABLE ='FETCH_QUAL_ACTIVITTIES_TABLE',
    FETCH_COUNTS_KPIS_TABLE ='FETCH_COUNTS_KPIS_TABLE',
    ADD_FILTER ='ADD_FILTER',
    RESET_TABLES = 'RESET_TABLES'
}

export enum TablesGetters{
    GET_COUNTS_TABLE = 'GET_COUNTS_TABLE',
    GET_KPIS_TABLE = 'GET_KPIS_TABLE',
    GET_ACTIVITIES_TABLES= 'GET_ACTIVITIES_TABLES',
    GET_QUAL_ACTIVITIES_TABLES= 'GET_QUAL_ACTIVITIES_TABLES',
    
}

export interface TableFIlter{

    filterkey: string
    filters: Array<DropDownItem>
}


