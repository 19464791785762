







































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class ISCDownloader extends Vue {
  @Prop() exporturl!: string;
  max_retries = 3;
  private tries: number = 0;

  async exportDataToCSV() {
    this.$emit("applied");
  }

  async downloadExcel(url) {
    fetch(url).then((res) => {
      if (res.status == 200) {
        res.blob().then((data) => {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.target = "_blank";
          a.click();
        });
      } else {
        this.tries += 1;
        if (this.tries < this.max_retries) {
          this.downloadExcel(url);
          this.tries = 0;
        } else {
          // error handle
        }
      }
    });
  }
}
