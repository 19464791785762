
























































import { Component, Vue } from "vue-property-decorator";
import SidebarModule from "@/store/modules/sidebar/sidebar.store";
import { getModule } from "vuex-module-decorators";
import {
  SidebarGetters,
  SidebarActions,
  SidebarActionSelectionDeps
} from "@/store/modules/sidebar/sidebar.types";
import TablesModule from "@/store/modules/tables/tables.store";
import { TablesActions } from "@/store/modules/tables/tables.types";
import GlobalModule from "@/store/modules/global/global.store";
import {
  GlobalActions,
  GlobalGetters
} from "@/store/modules/global/global.types";
import { DEFAULT_SEGMENTS } from "@/services/constants";
import Client from '@/types/Client';


@Component({})
export default class DashboardSelection extends Vue {
  public sidebarModule: SidebarModule = getModule(SidebarModule, this.$store);

  public tableModule: TablesModule = getModule(TablesModule, this.$store);

  public globalModule: GlobalModule = getModule(GlobalModule, this.$store);

  public sidebarGetters = SidebarGetters;

  public globalGetters = GlobalGetters;

  public displayNext: boolean = false;

  public start_date: string | null = this.sidebarModule[
    SidebarGetters.GET_SQUARE_START_DATE
  ];

  public end_date: string = new Date().toISOString().substring(0, 10);

  async created() {

    await this.sidebarModule[SidebarActions.SELECT_SEGMENT](
      DEFAULT_SEGMENTS[0]
    );
  }

  async removeSquare(square) {
    await this.sidebarModule[SidebarActions.REMOVE_SQAURE](square);
  }

  async generateDashboard() {
    const server = this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER];
    const client: Client | null = this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT];

    const segment = this.sidebarModule[SidebarGetters.GET_SELECTED_SEGMENT];
    const sessionId = this.sidebarModule[SidebarGetters.GET_SESSION_ID];

    const body = {
      segment_input: segment?.title,
      id: sessionId,
      serverId: server?.value,
      clientCode: client?.Code,
      start_date_input:
        this.start_date == null
          ? this.sidebarModule[SidebarGetters.GET_SQUARE_START_DATE]
          : this.start_date,
      end_date_input:
        this.end_date == null
          ? new Date().toISOString().substring(0, 10)
          : this.end_date
    };

    await this.tableModule[TablesActions.RESET_TABLES]();

    await this.tableModule[TablesActions.FETCH_COUNTS_KPIS_TABLE](body);
  }

  async addSegmentFilters(value) {
    await this.sidebarModule[SidebarActions.ADD_SEGMENT_FILTERS](value);
  }

  async selectSegment(value) {
    await this.sidebarModule[SidebarActions.SELECT_SEGMENT](value);

    await this.tableModule[TablesActions.RESET_TABLES]();
  }

  async selectServer(value) {
    await this.sidebarModule[SidebarActions.SELECT_SERVER](value);

    await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](
      SidebarActionSelectionDeps[SidebarActions.SELECT_SERVER]
    );

    await this.tableModule[TablesActions.RESET_TABLES]();

    await this.sidebarModule[SidebarActions.FETCH_CLIENTS]();
  }

  async fetchSquareData() {
    const selected_squares: Array<number> = [];

    this.sidebarModule[SidebarGetters.GET_SELECTED_SQUARES]?.map(el =>
      selected_squares.push(el.SquareId)
    );


    const time_predictor_payload = {
      serverId: this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER]?.value,
      clientCode: this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT]?.Code,
      squareId: selected_squares
    };



    await this.globalModule[GlobalActions.TIME_ESTIMATOR](
      time_predictor_payload
    );

    await this.tableModule[TablesActions.RESET_TABLES]();
    await this.sidebarModule[SidebarActions.FETCH_DATA]();
    this.globalModule.SET_FETCH_DATA_STATE(true);
    this.displayNext = true;
  }

  async selectClient(value) {
    await this.sidebarModule[SidebarActions.SELECT_CLIENT](value);

    await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](
      SidebarActionSelectionDeps[SidebarActions.SELECT_CLIENT]
    );

    await this.tableModule[TablesActions.RESET_TABLES]();

    await this.sidebarModule[SidebarActions.FETCH_PROGRAMS]();
  }

  async selectProgram(value) {
    this.sidebarModule[SidebarActions.SELECT_PROGRAM](value);
    await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](
      SidebarActionSelectionDeps[SidebarActions.SELECT_PROGRAM]
    );

    await this.tableModule[TablesActions.RESET_TABLES]();

    await this.sidebarModule[SidebarActions.FETCH_SQUARES]();
  }

  async addSquare(value) {
    await this.sidebarModule[SidebarActions.ADD_SQUARE](value);
  }
}
