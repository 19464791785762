




































import { Component, Prop, Vue } from "vue-property-decorator";
import DropDownItem from '@/types/DropDownItem';
import TablesModule from '@/store/modules/tables/tables.store';
import { getModule } from 'vuex-module-decorators';
import _ from 'lodash'
// import Table from '@/types/Table';
// import { TablesActions } from '@/store/modules/tables/tables.types';
// import { getModule } from 'vuex-module-decorators';
// import ExportModule from '@/store/modules/exports/export.store';
// import { ExportGetters, ExportActions } from '@/store/modules/exports/export.type';
// import DropDownItem from '@/types/DropDownItem';

@Component({})

export default class TableFilter extends Vue{
    
    @Prop() filterOptions
    
    @Prop() title

    @Prop () filterKey 

    @Prop () tablename

    @Prop () tablemutatator
    @Prop() filter

    @Prop() head

    // @Prop () selectedFilters

    public column_mapping = {
      "Square Name": "NONE_RANGE",
      "Role": "NONE_RANGE",
      "Activity Id": 'RANGE',
      "Activity Name": "NONE_RANGE",
      "Status": "NONE_RANGE",
      "Start Date": "DATE_RANGE",
      "#Invited": "RANGE",
      "#Completes": "RANGE",
      "#To do": "RANGE",
      "#In Progress": "RANGE",
      "Total # of posts": "RANGE",
      "Average #words per post": "RANGE",
      "#Replies to posts": "RANGE",
      "#Pictures": "RANGE",
      "#Video": "RANGE",
      "#Likes to posts": "RANGE",
      "#Flagged posts": "RANGE",
      "#Favourite posts": "RANGE",
      "#Non-tagged posts": "RANGE",
      "Response rate": "RANGE"
    }

    public selectedFilters: Array<DropDownItem> =[]
    public tableModule: TablesModule = getModule(TablesModule, this.$store)


    public getMaxRange(){
        const values = _.orderBy(this.filterOptions, ['value'], ['asc'])

        return parseInt(values[0].value)

    }

     public getMinRange(){
        const values = _.orderBy(this.filterOptions, ['value'], ['desc'])
      
        if(values[0].value == ""){
            return parseInt(values[1].value)
        }
        return parseInt(values[0].value)

    }

    get getSelectedFilter(): Array<DropDownItem>{
   
        const values: Array<DropDownItem> =[]


        if(this.filter){
            this.filter.filterValues.forEach(element => {
                values.push({title: element, value: element, mutaulExclusive: false})
            });
        
        }

        
        this.selectedFilters = values

        return values
    }

    async addFilter(value){

        this.$emit('addFilter', value)


    }

    removeFilter(value){

        this.$emit('removeFilter', value)

    }
}
