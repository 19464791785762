<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-sheet
              min-height="70vh"
              rounded="lg">

              <!-- <isc-datatable
              v-if="members_table"
              :datatable="members_table"
              :tablename="tablename"
              caption="Members"
              :datatable_page_limit="10"/> -->

            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

// import { computed} from '@vue/composition-api'
// import * as TableTypes from '../store/tables/types'

export default {

    // setup(props, { root }){
    //   const tablename = TableTypes.getters.MEMBERS_TABLE_DATA
    //   const members_table = computed(() => root.$store.getters[TableTypes.getters.MEMBERS_TABLE_DATA]);
        
    //   return{members_table, tablename}
    // }
  }
</script>