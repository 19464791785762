

















import { Component,  Vue } from "vue-property-decorator";
import GlobalModule from '@/store/modules/global/global.store';
import { getModule } from 'vuex-module-decorators';
import { GlobalGetters } from '@/store/modules/global/global.types';
@Component({})
export default class NotificationBar extends Vue{


    public globalModule: GlobalModule = getModule(GlobalModule, this.$store)
    public globalGetters = GlobalGetters

}
