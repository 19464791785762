<template>
  <div class="mt-2 mb-2">
    <div>
      <span class="font-weight-bold black--text ">
        {{title}}
      </span>
    </div>

    <vSelect
      class="ml-0 pl-0 dd-select style-chooser"
      :clearable="clearable"
      v-model="value"
      :label="label"
      :options="options"
    >
      <!-- <template v-slot:option="option">
          <div class="select_option">{{option.title}}</div>
        </template> -->
    </vSelect>
  </div>
</template>

<script>

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { ref, watch } from '@vue/composition-api';
export default {
    setup(props, {emit}){

        const value = ref(props.defaultValue)

        watch(value, (next) =>{
          if(next == null){
            value.value = props.defaultValue
          }

          emit('applied', value.value)
        })

        return {value}
    },

    components:{vSelect},
    props:{

      clearable:{
        type: Boolean, default: false
      },
      defaultValue:{
        type: Object
      },
      selectedValue: {
        type: Object
      },
      options: {
        type: Array
      },
      label:{
        type: String
      },
      title:{
        type: String

      },
      selected: {
        type: Object
      },
      mutltiple:{
        type: Boolean,
        default: false
      }
    },


}
</script>

<style>

  .dd-select {
  border: solid;
  color:white;

  }

  .style-chooser .vs__dropdown-menu ul{
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

   .style-chooser .vs__dropdown-menu{
    padding-left: 1em !important;
    margin-left: 0px !important;
  }


  .select_option{

    /* color: red;
    margin: 0px !important;
    padding: 0px !important;
    text-align: left;
    text-justify: initial; */
  }

  .dropdown li{
    padding: 0 !important;
    margin: 0 !important;
  }

  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu,
  .style-chooser .vs__dropdown-color,
  .style-chooser .vs__dropdown-option-color,
  .style-chooser .vs__controls-color,
  .style-chooser .vs__border-color {
    color: #004750 !important;
  }

  .style-chooser .vs__dropdown-option--highlight {
    color: #ffffff !important;
    background: #004750 !important;
  }

  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    color: #004750 !important;
  }
</style>
