import globalApi from "@/services/API/global.api";
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import store from "../..";
import { GlobalActions, GlobalGetters, GlobalMutation } from "./global.types";

export interface GlobalState{
    loading: boolean
    error_msg: string| null
    fetchDataSate: boolean
}

export interface Error {
    message: string | null,
    type: string | null
}
@Module({
    name: 'GlobalModule',
    namespaced: true,
    stateFactory: true,
    dynamic: true,
    store
})
class GlobalModule extends VuexModule implements GlobalState {
    public loading: boolean = false;
    public error_msg: string | null = null;

    public fetchDataSate: boolean = false
    
    public is_authenticated: boolean = false

    public time_estimation: string | null = null

    @Action
    async [GlobalActions.TIME_ESTIMATOR](payload){

        try {
            this.context.dispatch(GlobalActions.LOAD_API)
            
            const res = await globalApi.fetchtime_estimation(payload)

            this.context.commit(GlobalMutation.SET_TIME_ESTIMATION, res.data[0])

           
        } catch (error) {

            // this.context.dispatch(GlobalActions.LOAD_ERROR, 'Invalid token')
            
        }
        

}

    @Action
    async [GlobalActions.AUTHENTICATE_TOKEN](token){
        if(!token){
            this.context.dispatch(GlobalActions.LOAD_ERROR, 'Token not provided');
            return;
        } 

        try {
            this.context.dispatch(GlobalActions.LOAD_API)
            const res = await globalApi.authenticate(token)
            if(res.status == 200){
                this.context.commit(GlobalMutation.SET_IS_AUTHENTICATED, true)

                await localStorage.setItem('token', token)

                await this.context.dispatch(GlobalActions.CONCLUDE_API_CALL)
                this.CONCLUDE_API_CALL()
            }else if(res.status == 401){
                this.context.dispatch(GlobalActions.LOAD_ERROR, 'Invalid token')
                
            }

           
        } catch (error) {

            this.context.dispatch(GlobalActions.LOAD_ERROR, 'Invalid token')
            
        }
        

    }

    @Action
    [GlobalActions.LOAD_ERROR](error_msg){
     
        this.context.commit(GlobalMutation.SET_ERROR_MSG, error_msg)
        this.context.commit(GlobalMutation.SET_LOADING, false)
    }

    @Action
    [GlobalActions.RESET_TIME_ESTIMATOR](){
        
        this.context.commit(GlobalMutation.SET_TIME_ESTIMATION, null)
    }

    @Action
    [GlobalActions.LOAD_API](){
        

        this.context.commit(GlobalMutation.SET_ERROR_MSG, null)
        this.context.commit(GlobalMutation.SET_LOADING, true)
    }

    @Action
    [GlobalActions.CONCLUDE_API_CALL](){
        this.context.commit(GlobalMutation.SET_ERROR_MSG, null)
        this.context.commit(GlobalMutation.SET_LOADING, false)
        
    }


    @Action({commit: GlobalMutation.SET_LOADING})
    [GlobalActions.TOGGLE_LOADING](){
        return !this.loading
    }

    @Action({commit: GlobalMutation.SET_FETCH_DATA_STATE})
    [GlobalActions.TOGGLE_FETCH_DATA_STATE](){ return !this.fetchDataSate}

    @Mutation
    [GlobalMutation.SET_ERROR_MSG](errorMsg: string){
        this.error_msg = errorMsg
    }

    @Mutation
    [GlobalMutation.SET_LOADING](loading: boolean){
        this.loading = loading
    }

    @Mutation
    [GlobalMutation.SET_FETCH_DATA_STATE](fetchData: boolean){
        this.fetchDataSate = fetchData
    }

    @Mutation
    [GlobalMutation.SET_TIME_ESTIMATION](time: string | null){
        this.time_estimation = time
    }

    @Mutation
    [GlobalMutation.SET_IS_AUTHENTICATED](is_auth: boolean){
        this.is_authenticated = is_auth
    }

    get [GlobalGetters.GET_IS_AUTHENTICATED](): boolean{
        return this.is_authenticated
    }

    get [GlobalGetters.GET_ERROR_MSG](): string | null{
        return this.error_msg
    }

    get [GlobalGetters.GET_LOADING](): boolean{
        return this.loading
    }

    get [GlobalGetters.GET_FETCH_DATA_STATE](): boolean{return this.fetchDataSate}
    
    get [GlobalGetters.GET_TIME_ESTIMATION](): string | null{return this.time_estimation}
}

export default GlobalModule