import DropDownItem from "@/types/DropDownItem";

import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "../../index";
import Segment from "@/types/Segment";
import GlobalModule from "../global/global.store";
import Square from "@/types/Sqaure";

import SquareSegment from '@/types/SquareSegment'

import Client from "@/types/Client";
import Program from "@/types/Program";

import _ from 'lodash'
import { DEFAULT_SEGMENTS } from "@/services/constants";
import { SidebarActions, SidebarGetters, SidebarMutation } from "./sidebar.types";
import { GlobalActions, GlobalMutation } from "../global/global.types";
import { deduplicateListSegmentLIst, filterSegmentListBySquareId, filterSegmentListBySquareIds, get_start_date, formatSquareSegments } from "./sidebar.services";
import sidebarApi from "@/services/API/sidebar.api";
import {v4 as uuidv4} from 'uuid';



@Module({
    name: 'SidebarModule',
    namespaced: true,
    stateFactory: true,
    dynamic: true,
    store
})

class SidebarModule extends VuexModule{

    private globalModule = getModule(GlobalModule, store)
   
    public serverList : Array<DropDownItem> | null = null;

    public selectedServer : DropDownItem | null = null
    public clientsList : Array<Client> | null =null
    public selectedClient: Client | null = null
    public programsList: Array<Program> | null =null
    public selectedProgram: Program | null = null
    public clientSquaresList : Array<Square> | null =null
    public selectedSquares: Array<Square> | null= null 
    public squareSegmentList: Array<SquareSegment> | null = null
    public filteredSquareSegmentList: Array<Segment> | null =null
    public selectedSegmentFilters: Array<DropDownItem> | null= null
    public squareStartDate: string | null = null
    public selectedSegment: Segment | null = null 
    public sessionId: string | null = null; 
    public act_start_date: string | null = null
    public qual_act_start_date: string | null = null

    @Action({commit:SidebarMutation.SET_SQUARE_START_DATE})
    [SidebarActions.SELECT_START_DATE](value: string | null){
      
        return value
    }

    @Action({commit:SidebarMutation.SET_SESSION_ID})
    [SidebarActions.CREATE_SESSION_ID](){
      
        return uuidv4();
    }
    
    @Action
    [SidebarActions.RESET_SIDEBAR_STATE](resets: Array<any>){

        this.globalModule[GlobalMutation.SET_FETCH_DATA_STATE](false)
        resets.map(reset =>{
            this.context.commit(reset,null)
        })
    }

    @Action({commit:SidebarMutation.SET_SERVER_LIST })
    async [SidebarActions.FETCH_SERVERS]() {

        this.globalModule[GlobalActions.LOAD_API]()
        try {

            const  servers: Array<DropDownItem> =[]
            const res = await sidebarApi.getServers()
            res.data.map(server =>  servers.push(server))
            this.globalModule[GlobalActions.CONCLUDE_API_CALL]()

            return servers;
        } catch (error) {

            if(localStorage.getItem('token') != null){
                this.globalModule[GlobalActions.LOAD_ERROR]("Sorry the server is down Please contact admin")
            }else{
                this.globalModule[GlobalActions.LOAD_ERROR]("")
            }
        }
    }

    @Action
    async [SidebarActions.FETCH_CLIENTS](){

          this.globalModule[GlobalActions.LOAD_API]()

        const clients: Array<Client>=[]

        try {
            
            const res = await sidebarApi.getClients(this.selectedServer?.value)
        
            if(res.data.errmsg){
                this.globalModule[GlobalActions.LOAD_ERROR](res.data.errmsg[0])
            }
            else{
                res.data.map(el =>  clients.push({...el, title: el.Name, value: el.Code}))
    
                this.globalModule[GlobalActions.CONCLUDE_API_CALL]()
    
                await this.context.commit(SidebarMutation.SET_CLIENTS_LIST, clients)
            }

        } catch (error) {
            this.globalModule[GlobalActions.LOAD_ERROR](error)
        }
    }

    @Action 
    async [SidebarActions.SELECT_CLIENT](selectedClient: Client){
      
        await this.context.commit(SidebarMutation.SET_SELECTED_CLIENT, selectedClient)
    }

    @Action
    async [SidebarActions.FETCH_PROGRAMS](){

        this.globalModule[GlobalActions.LOAD_API]()

        try {
            const  programs: Array<Program> =[]
     
            const res = await sidebarApi.getPrograms(this.selectedServer?.value, this.selectedClient?.Code)
            
            if(res.data.errmsg){
                this.globalModule[GlobalActions.LOAD_ERROR](res.data.errmsg[0])
            }else{
                res.data.map(el =>  programs.push({...el, title: el.Program_Name, value: el.ProgramId}))

                this.context.commit(SidebarMutation.SET_PROGRAMS_LIST, programs)
            
                this.globalModule[GlobalActions.CONCLUDE_API_CALL]()
            }
        } catch (error) {
           this.globalModule[GlobalActions.LOAD_ERROR](error)
        }
      
    }

    @Action
    async [SidebarActions.ADD_SEGMENT_FILTERS](segmentFilters: Array<DropDownItem>){
        this.context.commit(SidebarMutation.SET_SELECTED_SEGMENT_FILTERS,segmentFilters)
    }

    @Action
    async [SidebarActions.ADD_SEGMENT_FILTER](segmentFilter: DropDownItem){

        if(this.selectedSegmentFilters){

            if(segmentFilter.title == 'None' || this.selectedSegmentFilters[0].title == 'None'){
                this.context.commit(SidebarMutation.SET_SELECTED_SEGMENT_FILTERS, [segmentFilter])
            }
            else{
                const filter = _.find(this.selectedSegmentFilters, el => el.value == segmentFilter.value)

                if(filter == undefined){

                    const selected_filters = this.selectedSegmentFilters

                    selected_filters.push(segmentFilter)

                    this.context.commit(SidebarMutation.SET_SELECTED_SEGMENT_FILTERS, selected_filters)

                }
            }

        }
        else{
            this.context.commit(SidebarMutation.SET_SELECTED_SEGMENT_FILTERS, [segmentFilter])
        }

    }

    @Action
    async [SidebarActions.ADD_SQUARE](selectedSquares: Array<Square>){

        if(selectedSquares.length == 0){
            await this.context.commit(SidebarMutation.SET_SELECTED_SQUARES_LIST, null)
        }else{
            await this.context.commit(SidebarMutation.SET_SELECTED_SQUARES_LIST, selectedSquares)
        }

       
        await this.context.dispatch(SidebarActions.FILTER_SEGMENTS_BY_SQUARES)
        
    }

    @Action
    async [SidebarActions.FILTER_SEGMENTS_BY_SQUARES](){
       

        if(this.selectedSquares){
            const filtered_segments = filterSegmentListBySquareIds(this.squareSegmentList, this.selectedSquares)
            const square_min_date: string = get_start_date('date_min', this.selectedSquares) 
            const act_min_date: string = get_start_date('act_mindate', this.selectedSquares) 
            const qual_act_min_date: string = get_start_date('qualact_mindate', this.selectedSquares) 
           
            await this.context.dispatch(SidebarActions.SELECT_START_DATE, square_min_date )
    
            await this.context.commit(SidebarMutation.SET_ACT_START_DATE, act_min_date)
            
            await this.context.commit(SidebarMutation.SET_QUAL_ACT_START_DATE, qual_act_min_date)
            
            await this.context.commit(SidebarMutation.SET_FILTERED_SQUARE_SEGMENTS,filtered_segments)
        }
        
    }

    @Action
    async [SidebarActions.REMOVE_SQAURE](square:Square){

      
        const remaining: Array<Square> =[]

      
        this.selectedSquares?.map((el:Square)=>{
            if(el.SquareId != square.SquareId ){
                remaining.push(el)
            }
        })

      
        await this.context.commit(SidebarMutation.SET_SELECTED_SQUARES_LIST, remaining)

    
    }

    @Action({commit: SidebarMutation.SET_SELECTED_SQUARES_LIST})
    async [SidebarActions.SELECT_SQUARE](selectedSquares: Array<Square> | null){

        const filteredSquareSegmentList: Array<Segment>= []

        selectedSquares?.map((el: Square) => {

            const filteredSegments: Array<Segment> =filterSegmentListBySquareId(this.squareSegmentList, el.value)

            filteredSegments.map(sg => filteredSquareSegmentList.push(sg))
        
        })
        
        const deduplicatedList: Array<Segment> =deduplicateListSegmentLIst(filteredSquareSegmentList)
      
        const square_min_date: string = get_start_date('date_min', selectedSquares) 
        const act_min_date: string = get_start_date('act_mindate', selectedSquares) 
        const qual_act_min_date: string = get_start_date('qualact_mindate', selectedSquares) 
       
        await this.context.dispatch(SidebarActions.SELECT_START_DATE, square_min_date )

        await this.context.commit(SidebarMutation.SET_ACT_START_DATE, act_min_date)
        await this.context.commit(SidebarMutation.SET_QUAL_ACT_START_DATE, qual_act_min_date)
        
        await this.context.commit(SidebarMutation.SET_FILTERED_SQUARE_SEGMENTS,deduplicatedList)

        return selectedSquares
    }

    @Action
    async [SidebarActions.SELECT_PROGRAM](selectedProgram: Program){
        this.context.commit(SidebarMutation.SET_SELECTED_PROGRAM, selectedProgram)
    }

    @Action
    async [SidebarActions.FETCH_SQUARES](){

        this.globalModule[GlobalActions.LOAD_API]()

        try {
            const  squares: Array<DropDownItem> =[]

            
            const res = await sidebarApi.getSquares(this.selectedServer?.value, this.selectedClient?.Code, this.selectedProgram?.value)
          
            if(res.data.errmsg){
                this.globalModule[GlobalActions.LOAD_ERROR](res.data.errmsg[0])
            }else{
          
                const {Squares, Segments} = res.data

              
                const squareSegments : Array<SquareSegment> =[]


                Segments.map(segment => {
                    

                    const square_id = segment.squareId[0]
                    const square_segments = segment.Segments

                    const formated_square_segments =  formatSquareSegments(square_id, square_segments)
                    
                    squareSegments.push({squareId: square_id, Segments: [DEFAULT_SEGMENTS,formated_square_segments ].flat()})
     
                })


                Squares.map(el =>  squares.push({...el, title: el.Square_Name, value: el.SquareId, mutaulExclusive: false}))


                await this.context.commit(SidebarMutation.SET_CLIENT_SQUARES_LIST, squares)
                await this.context.commit(SidebarMutation.SET_SQUARE_SEGMENT_LIST, squareSegments)

                await this.context.commit(SidebarMutation.SET_SELECTED_SEGMENT, DEFAULT_SEGMENTS[0])
                
            
                this.globalModule[GlobalActions.CONCLUDE_API_CALL]()

            }
        } catch (error) {            
            this.globalModule[GlobalActions.LOAD_ERROR](error)
        }
    }

    @Action({commit: SidebarMutation.SET_SELECTED_SEGMENT})
    async [SidebarActions.SELECT_SEGMENT](segment: Segment){

        return segment
    }

    @Action({commit: SidebarMutation.SET_SELECTED_SEGMENT_FILTERS})
    async [SidebarActions.SELECT_SEGMENT_FILTERS](segmentFilters: Array<DropDownItem> | null){
        return segmentFilters
    }

    @Action({commit: SidebarMutation.SET_SELECTED_SERVER})
    async [SidebarActions.SELECT_SERVER](selectedServer: DropDownItem){ 
        
        this.globalModule[GlobalMutation.SET_FETCH_DATA_STATE](false)
       
        return selectedServer;
    }

    @Action
    async [SidebarActions.FETCH_DATA](){

        this.globalModule[GlobalActions.LOAD_API]()

        const squareIds :Array<number|null|string> =[]

        this.selectedSquares?.map(el => squareIds.push(el.value))

        const body = {
            token: localStorage.getItem('token'),
            serverId: this.selectedServer?.value,
            clientCode: this.selectedClient?.Code,
            programId: this.selectedProgram?.value,
            squareId: squareIds,
            id: this.sessionId
        }

        try {

            const res = await sidebarApi.fetchData(body)
            if(res.data.errmsg){
             
                await this.globalModule[GlobalActions.RESET_TIME_ESTIMATOR]()
                this.globalModule[GlobalActions.LOAD_ERROR](res.data.errmsg[0])
                
                
            }else{
                await this.globalModule[GlobalActions.RESET_TIME_ESTIMATOR]()
                this.globalModule[GlobalActions.TOGGLE_FETCH_DATA_STATE]()
                
                this.globalModule[GlobalActions.CONCLUDE_API_CALL]()
                
            }
            
        } catch (error) {
          
          
            
            this.globalModule[GlobalActions.LOAD_ERROR](error)
        }
    }

    @Mutation
    [SidebarMutation.SET_SQUARE_START_DATE](value: string | null){
           
        this.squareStartDate = value
    }


    @Mutation
    [SidebarMutation.SET_FILTERED_SQUARE_SEGMENTS](filteredSquareSegmentList: Array<Segment>): void{

        this.filteredSquareSegmentList = filteredSquareSegmentList
    }

    @Mutation
    [SidebarMutation.SET_SELECTED_SERVER](selectedServer: DropDownItem): void{
        
        this.selectedServer = selectedServer
    }
    
    @Mutation
    [SidebarMutation.SET_SERVER_LIST](serverList: Array<DropDownItem>): void{
        this.serverList = serverList
    }

    @Mutation
    [SidebarMutation.SET_CLIENTS_LIST](clientsList: Array<Client>): void{
      
        this.clientsList = clientsList
    }

    @Mutation
    [SidebarMutation.SET_SELECTED_CLIENT](selecedClient: Client): void{
        this.selectedClient = selecedClient
    }

    @Mutation
    [SidebarMutation.SET_PROGRAMS_LIST](programsList: Array<Program>): void{
        this.programsList = programsList
    }

    @Mutation
    [SidebarMutation.SET_SELECTED_PROGRAM](selectedProgram: Program): void{
        this.selectedProgram = selectedProgram
    }

    @Mutation
    [SidebarMutation.SET_CLIENT_SQUARES_LIST](clientSquaresList: Array<Square>): void{
        this.clientSquaresList = clientSquaresList
    }

    @Mutation
    [SidebarMutation.SET_SELECTED_SQUARES_LIST](selectedSquaresList: Array<Square>): void{
        this.selectedSquares = selectedSquaresList
    }

    @Mutation
    [SidebarMutation.SET_SQUARE_SEGMENT_LIST](squareSegmentList: Array<SquareSegment>): void{
        this.squareSegmentList = squareSegmentList
    }

    @Mutation
    [SidebarMutation.SET_SELECTED_SEGMENT_FILTERS](selectedSegmentFilters: Array<DropDownItem>){
        this.selectedSegmentFilters = selectedSegmentFilters
    }
    
    @Mutation
    [SidebarMutation.SET_SELECTED_SEGMENT](selected_segment: Segment){
        this.selectedSegment = selected_segment
    }

    @Mutation
    [SidebarMutation.SET_SESSION_ID](id: string | null){
        this.sessionId = id
    }

    @Mutation
    [SidebarMutation.SET_ACT_START_DATE](act_start_date: string){
        this.act_start_date = act_start_date
    }

    @Mutation
    [SidebarMutation.SET_QUAL_ACT_START_DATE](qual_act_min_date: string){
        this.qual_act_start_date = qual_act_min_date
    }

    get [SidebarGetters.GET_ACT_START_DATE](): string | null {
        return this.act_start_date 
    }

    get [SidebarGetters.GET_SESSION_ID](): string | null {
        return this.sessionId;
    }

    get [SidebarGetters.GET_QUAL_ACT_START_DATE](): string | null {
        return this.qual_act_start_date
    }

    get [SidebarGetters.GET_SQUARE_START_DATE] (): string | null{
        return this.squareStartDate
    }

    get [SidebarGetters.GET_SELECTED_SEGMENT_FILTERS](): Array<DropDownItem> | null{
        return this.selectedSegmentFilters
    }


    get [SidebarGetters.GET_SELECTED_SEGMENT](): Segment | null{
        return this.selectedSegment
    }

    get [SidebarGetters.GET_FILTERED_SQUARE_SEGMENT_LIST](): Array<Segment> | null{
        return this.filteredSquareSegmentList
    }

    
    get [SidebarGetters.GET_SERVER_LIST](): Array<DropDownItem> | null{
        return this.serverList;
    }
    
    get [SidebarGetters.GET_SELECTED_SERVER](): DropDownItem | null{
       
        return this.selectedServer;
    }

    get [SidebarGetters.GET_CLIENT_LIST] (): Array<Client> | null{
        return this.clientsList
    }

    get [SidebarGetters.GET_SELECTED_CLIENT](): Client | null{
        return this.selectedClient
    }

    get [SidebarGetters.GET_PROGRAMS_LIST](): Array<DropDownItem> | null{
        return this.programsList
    }

    get [SidebarGetters.GET_SELECTED_PROGRAM] (): DropDownItem | null{
        return this.selectedProgram
    }

    get [SidebarGetters.GET_CLIENT_SQUARE_LIST](): Array<DropDownItem> | null{
        return this.clientSquaresList
    }

    get [SidebarGetters.GET_SELECTED_SQUARES](): Array<Square> | null{
        return this.selectedSquares
    }

}

export default SidebarModule