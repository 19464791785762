




































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from 'vuex-module-decorators';
import ExportModule from '@/store/modules/exports/export.store';
import { ExportGetters, ExportActions } from '@/store/modules/exports/export.type';

@Component({})

export default class ISCDownloader extends Vue {

   
    @Prop() datatable
    @Prop() datatable_page_limit
    @Prop() caption

    public exportModule = getModule(ExportModule, this.$store)
    public exportGetters = ExportGetters
    public exportActions = ExportActions

    public search =''

    async exportDataToCSV(){
        await this.exportModule[ExportActions.EXPORT_TABLE_DATA_TO_CSV]({datatable: this.datatable, caption: this.caption})
    }
    
}
