import Vue from 'vue'
import Vuex from 'vuex'
// import UIModule from './modules/uiModule.store'
import GlobalModule from './modules/global/global.store'
import ServerList from "./modules/serverlist.store"
import SidebarModule from "./modules/sidebar/sidebar.store"
import TablesModule from "./modules/tables/tables.store"
import ExportModule from "./modules/exports/export.store"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  // getters:{},
  // modules:{
  
  //   //  SidebarModule,
  //   //  ServerList,
  //   //  GlobalModule
  // }

});
