


























import { Component, Prop, Vue } from "vue-property-decorator";
import DropDownItem from '@/types/DropDownItem';

@Component({})
export default class AddPointFilter extends Vue {

    @Prop() filter

    @Prop() head

    @Prop() filterOptions

   
    
    get getSelectedFilter(): Array<DropDownItem>{
   
        const values: Array<DropDownItem> =[]


        if(this.filter){
            this.filter.filterValues.forEach(element => {
                values.push({title: element, value: element, mutaulExclusive: false})
            });
        
        }

        
        // this.selectedFilters = values

        return values
    }

    async addFilter (value){

        console.log('Adding this filter  => ', value);

        this.$emit('addFilter', {filtername: this.head.value, filterValue: value.value, type:this.head.filtertype })
        // this.$emit('addFilter', value)
        

        

        // this.$emit('addRange', {min: this.range[0], max: this.range[1]})
        
    }

    async removeFilter(value){
        console.log('Removing Range => ', value);

        // this.$emit('removeFilter', value)


    }
}
