


























import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from 'vuex-module-decorators';
import ExportModule from '@/store/modules/exports/export.store';
import { ExportGetters, ExportActions } from '@/store/modules/exports/export.type';

@Component({})

export default class KPIsDatatable extends Vue {


    @Prop() datatable
    @Prop() datatable_page_limit
    @Prop() caption

    public exportModule = getModule(ExportModule, this.$store)
    public exportGetters = ExportGetters
    public exportActions = ExportActions

    async exportDataToCSV(){
        await this.exportModule[ExportActions.EXPORT_TABLE_DATA_TO_CSV]({datatable: this.datatable, caption: this.caption})
    }

    // showCalcationDescription(key){
    //     console.log('SHow calculations for ', key);

    // }

    // closeDescriptions(key){

    //     console.log('disable descriptions for ', key);
    //     // console.log(item);


    // }

    displayCol(item){

        if((typeof item) == 'number'){
            return `${item} %`
        }else{
             return item
        }


    }

    styleCol(key, item){


        const itemBenchmark = this.datatable.data[0][key]

        if((typeof item) == 'number' && itemBenchmark > 0){


            return this.pickColorFromBenchmark(item, itemBenchmark, key)
        }else{
            return "background-color: white"
        }
    }

    pickColorFromBenchmark(value, benchmark, key){

         if(benchmark == 0){
             return `background-color: white`
        }

        // const colors = ["rgb(234, 46, 126)", "rgb(239, 93, 155)", "rgb(244, 139, 184)", "rgb(248, 185, 212)", "rgb(253, 232, 241)", "rgb(255, 255, 255)", "rgb(237, 247, 247)", "rgb(202, 231, 232)", "rgb(166, 215, 216)", "rgb(131, 199, 201)", "rgb(95, 183, 185)"]
        const colors = [
          '#fbc5bd', // negatives
          '#fbcbc4',
          '#fcd1ca',
          '#fcd6d1',
          '#fddcd7',
          '#fde2de',
          '#b6f2a0', // positive
          '#bdf3aa',
          '#c5f5b3',
          '#ccf6bd',
          '#d3f7c6',
          '#dbf9d0'
        ]

        const negative_split = benchmark/ 6
        const positive_split = (100 - benchmark) / 6

        const red_bins = [0]
        const green_bins = [benchmark]

        for( let i =1 ; i<6; i++){
            red_bins.push(red_bins[i-1]+ negative_split)
            green_bins.push(green_bins[i-1]+ positive_split)
        }

        const bins = red_bins.concat(green_bins)

        bins.push(100)

        let color_idx: number = 0

        for(let x =0; x < bins.length ; x++){

            if(value <= bins[x] && value > bins[x-1]){
                color_idx = x
            }

            if(value == 100){
                color_idx = colors.length -1
            }
        }

        if(key == 'Unsubscribed (out of total)'){

            return `background-color: ${colors[colors.length- color_idx]}`
        }


        return `background-color: ${colors[color_idx]}`
    }

}
