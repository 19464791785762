
















import { Component , Vue, Prop } from "vue-property-decorator";
// import { getModule } from 'vuex-module-decorators'
// import { Filter } from '@/store/modules/filters/types';

@Component({})
export default class FiltersView extends Vue {

    @Prop() filters

    public removeFilter(value){
          
      this.$emit('removeFilter',  value)
    }

      
}
