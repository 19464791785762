






























































































import { Component,  Vue } from "vue-property-decorator";
import DropDownItem from '../../types/DropDownItem'
import { getModule } from 'vuex-module-decorators';
import SidebarModule from '../../store/modules/sidebar/sidebar.store'
import GlobalModule from '@/store/modules/global/global.store';
import Program from '@/types/Program';
import TablesModule from '@/store/modules/tables/tables.store';
import { SidebarGetters, SidebarActions, SidebarActionSelectionDeps } from '@/store/modules/sidebar/sidebar.types';
import { TablesActions } from '@/store/modules/tables/tables.types';
import { GlobalGetters, GlobalActions } from '@/store/modules/global/global.types';
import _ from 'lodash'
import Square from '@/types/Sqaure';
import { DEFAULT_SEGMENTS } from '@/services/constants';
import Client from '@/types/Client';

@Component({
  components: { DashboardSidebar },
})

export default class DashboardSidebar extends Vue {

   public sidebarModule = getModule(SidebarModule, this.$store);
   
   public gobalModule = getModule(GlobalModule, this.$store)

   public tablesModule  = getModule(TablesModule, this.$store)

   public start_date: string | null  =this.sidebarModule[SidebarGetters.GET_SQUARE_START_DATE]
   public end_date: string =new Date().toISOString().substring(0,10)

   public defaultSegment = DEFAULT_SEGMENTS[0]
   public defaultSegmentFilter = DEFAULT_SEGMENTS[0]
   public displayNext : boolean = false;
   public sidebarGetters = SidebarGetters
   public globalGetters = GlobalGetters

  async removeOption(value){


    let filtered_option = _.filter(this.sidebarModule[SidebarGetters.GET_SELECTED_SEGMENT_FILTERS], el => {
        return el.value != value.value
      })

    if(filtered_option.length == 0){
      filtered_option.push(this.defaultSegment)
    }
    

    await this.sidebarModule[SidebarActions.SELECT_SEGMENT_FILTERS](filtered_option)
    
  }

  async removeSquare (value){
    const filtered_squares = _.filter(this.sidebarModule[SidebarGetters.GET_SELECTED_SQUARES], el => {
     
        return el.value != value.value
      })

   await this.sidebarModule[SidebarActions.SELECT_SQUARE](filtered_squares)
  }

   async generateDashboard (){

      const server = this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER]
      const client = this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT]
    
      const segment = this.sidebarModule[SidebarGetters.GET_SELECTED_SEGMENT]
      const segment_filters = this.sidebarModule[SidebarGetters.GET_SELECTED_SEGMENT_FILTERS]

      this.start_date=this.sidebarModule[SidebarGetters.GET_SQUARE_START_DATE]

       const body = {
            segment_input: segment?.title,
            segmentfilt_input: segment_filters,
            serverId: server?.value,
            clientId: client?.value,
            start_date_input: this.start_date,
            end_date_input: this.end_date

        }


      await this.tablesModule[TablesActions.RESET_TABLES]()
      await this.tablesModule[TablesActions.FETCH_COUNTS_KPIS_TABLE](body)
    
   }

    async created() {
      await this.sidebarModule[SidebarActions.FETCH_SERVERS]()
      
    }

    async selectServer(server){

      await this.sidebarModule[SidebarActions.SELECT_SERVER](server)
      await this.tablesModule[TablesActions.RESET_TABLES]()
      await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](SidebarActionSelectionDeps[SidebarActions.SELECT_SERVER])
      await this.sidebarModule[SidebarActions.FETCH_CLIENTS]()
      

    }

    async selectClient(client: Client){
  
   
      // const selected_client: Client =client
      await this.sidebarModule[SidebarActions.SELECT_CLIENT](client)
      await this.tablesModule[TablesActions.RESET_TABLES]()
      await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](SidebarActionSelectionDeps[SidebarActions.SELECT_CLIENT])
      await this.sidebarModule[SidebarActions.FETCH_PROGRAMS]()
      this.displayNext = false;
    }

    async selectProgram(program){

      const selected_program: Program = program

      await this.sidebarModule[SidebarActions.SELECT_PROGRAM](selected_program)
      await this.tablesModule[TablesActions.RESET_TABLES]()
      await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](SidebarActionSelectionDeps[SidebarActions.SELECT_PROGRAM])
      await this.sidebarModule[SidebarActions.FETCH_SQUARES]()
    }

    async selectSquares (square){

      await this.tablesModule[TablesActions.RESET_TABLES]()
      const squares: Array<Square> | null = this.sidebarModule[SidebarGetters.GET_SELECTED_SQUARES]

      if(squares){
         squares?.push(square)

          await this.sidebarModule[SidebarActions.SELECT_SQUARE](squares)
      }else{
        await this.sidebarModule[SidebarActions.SELECT_SQUARE]([square])
      }
      // this.displayNext = false;
         
    }

    async fetchSquareData (){


      const selected_squares: Array<number> =[]

      this.sidebarModule[SidebarGetters.GET_SELECTED_SQUARES]?.map(el => selected_squares.push(el.SquareId))
      const time_predictor_payload ={
        serverId: this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER]?.value,
        clientId: this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT]?.value,
        squareId: selected_squares,
      }
      
      await this.gobalModule[GlobalActions.TIME_ESTIMATOR](time_predictor_payload)
   
  
      await this.tablesModule[TablesActions.RESET_TABLES]()
      await this.sidebarModule[SidebarActions.FETCH_DATA]()
      this.gobalModule.SET_FETCH_DATA_STATE(true);
         this.displayNext = true;
    }

    async selectSegment(segment){

      await this.tablesModule[TablesActions.RESET_TABLES]()    
      await this.sidebarModule[SidebarActions.SELECT_SEGMENT](segment) 
      await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](SidebarActionSelectionDeps[SidebarActions.SELECT_SEGMENT])

      
    }

    async selectSegmentOption (segment_filter){

      await this.tablesModule[TablesActions.RESET_TABLES]()
      
      let selected_segment_options: Array<DropDownItem> |null =this.sidebarModule[SidebarGetters.GET_SELECTED_SEGMENT_FILTERS] 

      if(segment_filter.value == 'None'){
        await this.sidebarModule[SidebarActions.SELECT_SEGMENT_FILTERS]([segment_filter])
      }else{

        if(selected_segment_options){

          if(selected_segment_options[0].value =='None'){
              selected_segment_options = []
          }
          selected_segment_options?.push(segment_filter)
          await this.sidebarModule[SidebarActions.SELECT_SEGMENT_FILTERS](selected_segment_options);
        }else{
           await this.sidebarModule[SidebarActions.SELECT_SEGMENT_FILTERS]([segment_filter]);
        }
        
      }
    }
  }
