















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import ExportModule from "@/store/modules/exports/export.store";
import {
  ExportGetters,
  ExportActions,
} from "@/store/modules/exports/export.type";
import DropDownItem from "@/types/DropDownItem";
import TableFilter from "@/components/molecules/TableFilter.vue";
import TablesModule from "@/store/modules/tables/tables.store";
import { Filter } from "@/store/modules/filters/types";
import _ from "lodash";

@Component({
  components: { TableFilter },
})
export default class FilterableDatatable extends Vue {
  @Prop() datatable;
  @Prop() datatable_page_limit;
  @Prop() caption;
  @Prop() tableMutator;
  @Prop() filters;

  @Prop() colorCode;

  public exportModule = getModule(ExportModule, this.$store);
  public exportGetters = ExportGetters;
  public exportActions = ExportActions;

  public tableModule = getModule(TablesModule, this.$store);

  public filter_col = "Square";

  public search = "";

  public filterValuesOptions: Array<DropDownItem> = [];

  public onHoverHeader: any = null;

  public showColumnDescription = false;

  public showSettingCard = false;

  public switch_tooltip = false;

  async addRange(range, header) {
    this.$emit("addRange", {
      range,
      filtername: header.value,
      type: header.filtertype,
    });
  }

  showHover(headers, key) {
    const header = _.find(headers, (header) => header.value == key);

    if (header.value == key) {
      return header.hover_desc;
    }
  }

  public displayDesc(event, headers, head) {
    if (event.clientX > 1500) {
      this.switch_tooltip = true;
    } else {
      this.switch_tooltip = false;
    }

    const header = _.find(headers, (header) => header.value == head.value);

    this.onHoverHeader = header;
  }

  public check_in_range(filterValues: Array<any>, value) {
    let found = false;

    filterValues.forEach((el) => {
      if (value > el.min && value < el.max) {
        found = true;
      }
    });

    return found;
  }

  public check_in_date_range(filterValues: Array<any>, value) {
    let found = false;

    filterValues.forEach((el) => {
      const startdate = new Date(el.min);
      const enddate = new Date(el.max);
      const dateValue = new Date(value);

      if (dateValue > startdate && dateValue < enddate) {
        found = true;
      }
    });
    return found;
  }

  get filteredTableData() {
    // var for table data based on filters
    let dt: Array<any> = this.datatable.data

    // no filters, return full prop data
    // else loop through filters array and re-filter dt
    if (this.filters.length > 0) {
      this.filters.forEach((element: Filter) => {
        dt = _.filter(dt, (row) => {
          const filteringValue = row[element.filtername];
          if (element.type == "range") {
            return this.check_in_range(element.filterValues, filteringValue);
          } else if (element.type == "date_range") {
            return this.check_in_date_range(
              element.filterValues,
              filteringValue
            );
          } else {
            return _.includes(element.filterValues, filteringValue);
          }
        });
      });
    }
    return dt;
  }

  getCurrentFilter(head) {
    const crtFilt = _.filter(
      this.filters,
      (el: Filter) => el.filtername == head
    );

    return crtFilt[0];
  }

  async exportDataToCSV() {
    await this.exportModule[ExportActions.EXPORT_TABLE_DATA_TO_CSV]({
      datatable: this.datatable,
      caption: this.caption,
    });
  }

  removeFilter(value) {
    this.$emit("removeFilter", value);
  }

  addFilter(value) {
    this.$emit("addFilter", value);
  }

  openFilter(head) {
    this.filterValuesOptions = [];
    const filterfromArray: Array<any> = [];
    const { value } = head;
    this.datatable.data.map((o: any) => {
      const filt = o[value];
      filterfromArray.push(filt);
    });

    Array.from(new Set(filterfromArray)).forEach((el) => {
      const option: DropDownItem = {
        value: el,
        title: el,
        mutaulExclusive: false,
      };
      this.filterValuesOptions.push(option);
    });
  }

  displayCol(item, headers, key, row) {
    const colunm = _.find(headers, (el) => el.value == key);
    const isBenchmarkRow = Object.values(row).includes("Benchmark");

    if (colunm) {
      if (item === "NaN" || item === "NA") {
        return "";
      }

      if (isBenchmarkRow && item == 0) {
        return "";
      }

      if (colunm.column_type == "percentage") {
        return `${item} %`;
      } else {
        return item;
      }
    } else {
      return item;
    }
  }

  styleCol(key, item, colorCode, row) {
    const itemBenchmark = this.datatable.data[0][key];

    const isBenchmarkRow = Object.values(row).includes("Benchmark");

    if (isBenchmarkRow) {
      return "background-color: white";
    }

    if (typeof item == "number" && itemBenchmark > 0 && colorCode) {
      return this.pickColorFromBenchmark(item, itemBenchmark, key);
    } else {
      return "background-color: white;";
    }
  }

  pickColorFromBenchmark(value, benchmark, key) {
    if (benchmark == 0) {
      return `background-color: white`;
    }

    if (key == "Unsubscribed (out of total)") {
      const colorSelection = value < benchmark ? "#B6F2A0" : "#FBC5BD";
      return `background-color: ${colorSelection};`;
    }

    const colorSelection = value >= benchmark ? "#B6F2A0" : "#FBC5BD";
    return `background-color: ${colorSelection};`;

  }
}

