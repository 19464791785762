





































import { Component, Vue } from "vue-property-decorator";
import SidebarModule from '@/store/modules/sidebar/sidebar.store';
import { getModule } from 'vuex-module-decorators';
import { SidebarGetters } from '@/store/modules/sidebar/sidebar.types';
import GlobalModule from '@/store/modules/global/global.store';
import { GlobalGetters } from '@/store/modules/global/global.types';
import Segment from '@/types/Segment';
import { DEFAULT_SEGMENTS } from '@/services/constants';
@Component({})
export default class ActivitySelection extends Vue {

    public sidebarModule: SidebarModule = getModule(SidebarModule, this.$store)

    public globalModule: GlobalModule = getModule(GlobalModule, this.$store)

    public sidebarGetters = SidebarGetters

    public globalGetters = GlobalGetters

    public selected_segment:Segment | null = DEFAULT_SEGMENTS[0]

    public displayNext = true

    public selected_options =[]

    public start_date = this.sidebarModule[SidebarGetters.GET_ACT_START_DATE]

    public end_date: string = new Date().toISOString().substring(0, 10);

    created() {
     this.selected_segment =  DEFAULT_SEGMENTS[0]
    }

    get segment_options(){
       return this.sidebarModule[SidebarGetters.GET_FILTERED_SQUARE_SEGMENT_LIST];
     }

    selectSegment(value){
      this.selected_segment = value
    }

    addSegmentFilters(value){
      this.selected_options= value
    }

    generateTable(){
       const segment_selection = {
          segment_input: this.selected_segment?.title,
          startDate: this.start_date,
          endDate: this.end_date
       }
      this.$root.$emit('filters-applied', segment_selection)
    }
}
