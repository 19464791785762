import { render, staticRenderFns } from "./QualActivitiesPage.vue?vue&type=template&id=4a49c9a4&scoped=true&"
import script from "./QualActivitiesPage.vue?vue&type=script&lang=ts&"
export * from "./QualActivitiesPage.vue?vue&type=script&lang=ts&"
import style0 from "./QualActivitiesPage.vue?vue&type=style&index=0&id=4a49c9a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a49c9a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VBtn,VCol,VDivider,VMain,VRow,VSheet})
