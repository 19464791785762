






import { Component,  Vue } from "vue-property-decorator";
import NotificationBar from './NotificationBar.vue'
@Component({
  components: {NotificationBar}
})
export default class BodyHeadBar extends Vue{
  constructor(){
    super()
  }

}

