




















import { Component,  Vue } from "vue-property-decorator";
import { getModule } from 'vuex-module-decorators'
import GlobalModule from '@/store/modules/global/global.store'
import {GlobalGetters} from '@/store/modules/global/global.types'


@Component({})
export default class Spinner extends Vue {

    public globalModule = getModule(GlobalModule, this.$store)
    public globalGetters = GlobalGetters


}
