
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AddDateRangeFilter extends Vue {

    @Prop() filter

    @Prop() head

   public start_date = ''

    public end_date = ''
   
    async addRange (){

       const filter = {filtername: this.head.value, filterValue: {min: this.start_date, max: this.end_date}, type:this.head.filtertype }

        this.$emit('addFilter', filter)
        
    }


}
