// import axios from 'axios';
import http, {post}  from '../HttpService'

class SidebarApi {
    
    /** Retrieves the server list for the Square region. 
     * @returns {Promise} JSON Array 
     * @description JSON return model:  {title : string , value : string}
     * @example [{title: "Belgium", value: 0}]
     * 
    */
    getServers(){

        return http.get('/square/servers', {headers: {'Authorization': `JWE ${localStorage.getItem('token')}`}});
    }

    /** Retrieves the server list for the Square region. 
     * @returns {Promise} JSON Array 
     * @description JSON return model:  {Id : number , Name : string, Code: string}
     * @example [{"Id":3635,"Name":"Old Mutual","Code":"7612"}]
     * 
    */
    getClients(serverid){
       
        return http.get( `/square/clients?serverId=${serverid}` , {headers: {'Authorization': `JWE ${localStorage.getItem('token')}`}});
    }

     /** Retrieves the server list for the Square region. 
     * @returns {Promise} JSON Array 
     * @description JSON return model:  {ProgramId : number , Program_Name : string}
     * @example [{"ProgramId":2,"Program_Name":"TEVA MIGRAINE SQUARE"}]
     * 
    */
    getPrograms(serverId, clientCode){

        return http.get(`/square/program?serverId=${serverId}&clientCode=${clientCode}`)
    }

    /** Retrieves the server list for the Square region. 
     * @returns {Promise} JSON Array 
     * @description JSON return model:  {title : string , value : string}
     * @example {"Squares": [
                        {
                        "SquareId": 1,
                        "Square_Name": "My Brain for Migraine",
                        "Language": "en",
                        "ProgramId": 2,
                        "Program_Name": "TEVA MIGRAINE SQUARE",
                        "date_min": "2017-07-12 10:18:58",
                        "date_max": "2018-07-29 09:29:08"
                        }],
                        
                    "ActivityRecruitmentPartner": [{
                            "ActivityRecruitmentPartnerId": 1,
                            "PartnerId": 3,
                            "RecruitmentPartner": "Online recruited"
                            }],

                    "Segments": [{
      "squareId": [
        "1"
      ],
      "Segments": [
        {
          "SegmentationId": 1,
          "Segment_options": [
            "None",
           
          ],
          "Segment": "week - ",
          "value": "week - "
        }] }
     * 
    */
    getSquares(serverId, clientCode, programId){
        return http.get(`/square/square_segments?serverId=${serverId}&clientCode=${clientCode}&programId=${programId}`)
    }

    fetchData(body){

      // const production = "https://rapi.insites-consulting.com";

        return post("/square/data", body)
        // return http.post("/square/data", body)
    }

    fetchCountsKpis(body){

      return post('/square/counts_kpis', {...body, token: localStorage.getItem('token')})
      // return http.post('/square/counts_kpis', body)
    }
    
  
}
/**
 * This class handles all queries to the R DATA API in order to provide the drop down data for filtering
 *  @class
 */
export default new SidebarApi();