





































import { Component , Vue } from "vue-property-decorator";
import GlobalModule from '@/store/modules/global/global.store'
import TablesModule from '@/store/modules/tables/tables.store'
import { getModule } from 'vuex-module-decorators'
import MessageOverlay from '../components/ui-components/MessageOverlay.vue'
import { TablesGetters, TablesActions, TablesMutations } from '@/store/modules/tables/tables.types';
import { GlobalGetters } from '@/store/modules/global/global.types';
import SidebarModule from '@/store/modules/sidebar/sidebar.store';
import { SidebarGetters } from '@/store/modules/sidebar/sidebar.types';
import FiltersModule from "@/store/modules/filters/filters.store"
import { FiltersActions, FilterGetters } from '@/store/modules/filters/filters.types';


@Component({
   components: { MessageOverlay }
})
export default class QualActivitiesPage extends Vue{

  public tableMutator = TablesMutations.SET_QUAL_ACTIVITIES

  public globalModule: GlobalModule = getModule(GlobalModule, this.$store)

  public tablesModule: TablesModule = getModule(TablesModule, this.$store)

  public sidebarModule: SidebarModule = getModule(SidebarModule,this.$store)
  public globalGetters = GlobalGetters

  public start_date = this.sidebarModule[SidebarGetters.GET_QUAL_ACT_START_DATE]

  public tablesGetters = TablesGetters

  public filtersModule: FiltersModule = getModule(FiltersModule, this.$store)

  public filterGetters = FilterGetters

  async addRange ( value ){

    await this.filtersModule[FiltersActions.ADD_QUAL_ACTIVITY_FILTERS]({...value,filterValue: value.range })

  }

  async resentingFilters(){
    await this.filtersModule[FiltersActions.CLEAR_QUAL_ACTIVITY_FILTERS]()
  }

  async removeFilter(value){

    await this.filtersModule[FiltersActions.REMOVE_QUAL_ACTIVITY_FILTERS](value)

  }

  async addFilter(value){

    await this.filtersModule[FiltersActions.ADD_QUAL_ACTIVITY_FILTERS](value)
  }

  async fetchQualActivities(value){


    this.tablesModule[TablesActions.FETCH_QUAL_ACTIVITTIES_TABLE]({...value,
      id: this.sidebarModule[SidebarGetters.GET_SESSION_ID],
      qualact_start_date: value.startDate,
      qualact_end_date: value.endDate,
      serverId: this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER]?.value,
      clientCode: this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT]?.Code})

  }

  mounted() {
    this.$root.$on('filters-applied', (segment_selection: any) => {
      this.fetchQualActivities(segment_selection)
    })
  }

}

