export enum GlobalGetters{
    GET_ERROR_MSG ='GET_ERROR_MSG',
    GET_LOADING ='GET_LOADING',
    GET_FETCH_DATA_STATE ='GET_FETCH_DATA_STATE',
    GET_IS_AUTHENTICATED ='GET_IS_AUTHENTICATED',
    GET_TIME_ESTIMATION ='GET_TIME_ESTIMATION'
}

export enum GlobalActions {
    LOAD_ERROR ='LOAD_ERROR',
    LOAD_API = 'LOAD_API',
    CONCLUDE_API_CALL = 'CONCLUDE_API_CALL',
    TOGGLE_LOADING = 'TOGGLE_LOADING',
    TOGGLE_FETCH_DATA_STATE = 'TOGGLE_FETCH_DATA_STATE',
    AUTHENTICATE_TOKEN ='AUTHENTICATE_TOKEN',
    TIME_ESTIMATOR ='TIME_ESTIMATOR',
    RESET_TIME_ESTIMATOR ='RESET_TIME_ESTIMATOR'
}

export enum GlobalMutation {
    SET_ERROR_MSG = 'SET_ERROR_MSG',
    SET_LOADING = 'SET_LOADING',
    SET_FETCH_DATA_STATE = 'SET_FETCH_DATA_STATE',
    SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
    SET_TIME_ESTIMATION = 'SET_TIME_ESTIMATION',
}