export enum SidebarActions {

    SELECT_START_DATE= 'SELECT_START_DATE',
    RESET_SIDEBAR_STATE ='RESET_SIDEBAR_STATE',
    FETCH_SERVERS ='FETCH_SERVERS',
    FETCH_CLIENTS ='FETCH_CLIENTS',
    SELECT_CLIENT = 'SELECT_CLIENT',
    FETCH_PROGRAMS = 'FETCH_PROGRAMS',
    SELECT_SQUARE ='SELECT_SQUARE',
    ADD_SQUARE ='ADD_SQUARE',
    ADD_SEGMENT_FILTER ='ADD_SEGMENT_FILTER',
    SELECT_PROGRAM = 'SELECT_PROGRAM',
    FETCH_SQUARES = 'FETCH_SQUARES',
    SELECT_SEGMENT = 'SELECT_SEGMENT',
    SELECT_SEGMENT_FILTERS = 'SELECT_SEGMENT_FILTERS',
    SELECT_SERVER = 'SELECT_SERVER',
    FETCH_DATA = 'FETCH_DATA',
    REMOVE_SQAURE = 'REMOVE_SQAURE',
    FILTER_SEGMENTS_BY_SQUARES = 'FILTER_SEGMENTS_BY_SQUARES',
    ADD_SEGMENT_FILTERS = 'ADD_SEGMENT_FILTERS',
    CREATE_SESSION_ID = 'CREATE_SESSION_ID'
}

export enum SidebarMutation {
    SET_SQUARE_START_DATE = 'SET_SQUARE_START_DATE',
    SET_QUAL_ACT_START_DATE = 'SET_QUAL_ACT_START_DATE',
    SET_ACT_START_DATE = 'SET_ACT_START_DATE',
    SET_FILTERED_SQUARE_SEGMENTS = 'SET_FILTERED_SQUARE_SEGMENTS',
    SET_SELECTED_SERVER = 'SET_SELECTED_SERVER',
    SET_SERVER_LIST ='SET_SERVER_LIST',
    SET_CLIENTS_LIST = 'SET_CLIENTS_LIST',
    SET_SELECTED_CLIENT ='SET_SELECTED_CLIENT',
    SET_PROGRAMS_LIST = 'SET_PROGRAMS_LIST',
    SET_SELECTED_PROGRAM = 'SET_SELECTED_PROGRAM',
    SET_CLIENT_SQUARES_LIST = 'SET_CLIENT_SQUARES_LIST',
    SET_SELECTED_SQUARES_LIST = 'SET_SELECTED_SQUARES_LIST',
    SET_SQUARE_SEGMENT_LIST = 'SET_SQUARE_SEGMENT_LIST',
    SET_SELECTED_SEGMENT_FILTERS = 'SET_SELECTED_SEGMENT_FILTERS',
    SET_SELECTED_SEGMENT ='SET_SELECTED_SEGMENT',
    SET_SESSION_ID='SET_SESSION_ID',
    
}

export enum SidebarGetters  {
    GET_SQUARE_START_DATE= 'GET_SQUARE_START_DATE',
    GET_ACT_START_DATE= 'GET_ACT_START_DATE',
    GET_QUAL_ACT_START_DATE= 'GET_QUAL_ACT_START_DATE',
    GET_SELECTED_SEGMENT_FILTERS= 'GET_SELECTED_SEGMENT_FILTERS',
    GET_SELECTED_SEGMENT = 'GET_SELECTED_SEGMENT',
    GET_FILTERED_SQUARE_SEGMENT_LIST = 'GET_FILTERED_SQUARE_SEGMENT_LIST',
    GET_SERVER_LIST = 'GET_SERVER_LIST',
    GET_SELECTED_SERVER = 'GET_SELECTED_SERVER',
    GET_CLIENT_LIST = 'GET_CLIENT_LIST',
    GET_SELECTED_CLIENT = 'GET_SELECTED_CLIENT',
    GET_PROGRAMS_LIST = 'GET_PROGRAMS_LIST',
    GET_SELECTED_PROGRAM = 'GET_SELECTED_PROGRAM',
    GET_CLIENT_SQUARE_LIST ='GET_CLIENT_SQUARE_LIST',
    GET_SELECTED_SQUARES = 'GET_SELECTED_SQUARES',
    GET_SESSION_ID = 'GET_SESSION_ID'

}


export const SidebarActionSelectionDeps ={
    [SidebarActions.SELECT_SERVER]: [
        SidebarMutation.SET_CLIENTS_LIST,
        SidebarMutation.SET_SELECTED_CLIENT,
        SidebarMutation.SET_PROGRAMS_LIST,
        SidebarMutation.SET_SELECTED_PROGRAM,
        SidebarMutation.SET_CLIENT_SQUARES_LIST,
        SidebarMutation.SET_SELECTED_SQUARES_LIST,
        SidebarMutation.SET_SQUARE_SEGMENT_LIST,
        SidebarMutation.SET_SELECTED_SEGMENT,
        SidebarMutation.SET_SELECTED_SEGMENT_FILTERS
    ],
    [SidebarActions.SELECT_CLIENT]: [
        SidebarMutation.SET_PROGRAMS_LIST,
        SidebarMutation.SET_SELECTED_PROGRAM,
        SidebarMutation.SET_CLIENT_SQUARES_LIST,
        SidebarMutation.SET_SELECTED_SQUARES_LIST,
        SidebarMutation.SET_SQUARE_SEGMENT_LIST,
        SidebarMutation.SET_SELECTED_SEGMENT,
        SidebarMutation.SET_SELECTED_SEGMENT_FILTERS
    ],
    [SidebarActions.SELECT_PROGRAM]:[
        SidebarMutation.SET_CLIENT_SQUARES_LIST,
        SidebarMutation.SET_SELECTED_SQUARES_LIST,
        SidebarMutation.SET_SQUARE_SEGMENT_LIST,
        SidebarMutation.SET_SELECTED_SEGMENT,
        SidebarMutation.SET_SELECTED_SEGMENT_FILTERS
    ],
    [SidebarActions.SELECT_SEGMENT]:[
        SidebarMutation.SET_SELECTED_SEGMENT_FILTERS
    ]
}