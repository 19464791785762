


























































































































import MessageOverlay from "../components/ui-components/MessageOverlay.vue";
import Message from "../components/ui-components/Message.vue";
import { Component, Vue } from "vue-property-decorator";

import { getModule } from "vuex-module-decorators";
import GlobalModule from "../store/modules/global/global.store";
import TablesModule from "@/store/modules/tables/tables.store";
import {
  TablesMutations,
  TablesActions,
  TablesGetters,
} from "@/store/modules/tables/tables.types";
import { GlobalGetters } from "@/store/modules/global/global.types";
import { SidebarGetters } from "@/store/modules/sidebar/sidebar.types";
import FiltersModule from "@/store/modules/filters/filters.store";
import {
  FilterGetters,
  FiltersActions,
} from "@/store/modules/filters/filters.types";

@Component({
  components: { MessageOverlay, Message },
})
export default class Dashboard extends Vue {
  public globalModule: GlobalModule = getModule(GlobalModule, this.$store);

  public tablesModule: TablesModule = getModule(TablesModule, this.$store);

  public tablesActions = TablesActions;
  public tablesMutations = TablesMutations;
  public tablesGetters = TablesGetters;

  public globalGetters = GlobalGetters;

  public sidebarGetters = SidebarGetters;

  public selected_squares_segment_list: any = [];
  public square_data_is_ready: any = false;

  public filtersModule: FiltersModule = getModule(FiltersModule, this.$store);

  public filterGetters = FilterGetters;

  public tableMutator = TablesMutations.SET_COUNTS_TABLE;

  async addKPIsRange(value) {
    await this.filtersModule[FiltersActions.ADD_KPIS_FILTER]({
      ...value,
      filterValue: value.filterValue,
    });
  }

  async addKPIsFilter(value) {
    await this.filtersModule[FiltersActions.ADD_KPIS_FILTER]({
      ...value,
      filterValue: value.filterValue,
    });
  }

  async removeKPIsFilter(value) {
    await this.filtersModule[FiltersActions.REMOVE_KPIS_FILTERS](value);
  }

  async resetKPISFilters() {
    await this.filtersModule[FiltersActions.CLEAR_KPIS_FILTERS]();
  }

  async resetCountFilters() {
    await this.filtersModule[FiltersActions.CLEAR_COUNTS_FILTERS]();
  }

  async removeCountsFilter(value) {
    await this.filtersModule[FiltersActions.REMOVE_COUNTS_FILTER](value);
  }

  async addCountFilter(value) {
    await this.filtersModule[FiltersActions.ADD_COUNTS_FILTERS]({
      ...value,
      filterValue: value.filterValue,
    });
  }

  async addCountRange(value) {
    await this.filtersModule[FiltersActions.ADD_COUNTS_FILTERS]({
      ...value,
      filterValue: value.filterValue,
    });
  }
}
