<template>
   <v-tabs background-color="#004750" color="white" align-with-title>
          <v-tabs-slider color="#7AE159"></v-tabs-slider>
          <v-tab
            class="tab"
            v-for="item in links"
            :key="item.name"
            :to="item.route"
          >
            {{ item.name }}
          </v-tab>
    </v-tabs>
</template>

<script>

import {ref} from "@vue/composition-api"
export default {

    setup(){

        const links = ref([
            {name: 'Square-Overview', route:'/'},
            {name: 'Activities Overview', route:'/activities'},
            // {name: 'Members', route:'/members'},
            {name: 'Qual Activities Overview', route:'/qualactivities'},
            ])

            return {links}
    }

}
</script>

<style>
.tab {
  background-color: "#004750";
  color: white !important
}
</style>
