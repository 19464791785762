import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import components from './component_registry'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import 'vuetify/dist/vuetify.min.css'
import VueCompositionAPI from '@vue/composition-api'


Vue.config.productionTip = false

Vue.use(vSelect)
Vue.use (VueCompositionAPI)

components.map(component => Vue.component(component.name, component.component))



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
