import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../views/Dashboard.vue'
import ActivityPage from '../views/ActivityPage.vue'
import MembersPage from '../views/MembersPage.vue'
import QualActivitiesPage from '../views/QualActivitiesPage.vue'
import AuthPage from '../views/AuthPage.vue'
import { component } from 'vue/types/umd'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{auth_required: true},
    component: Dashboard
  },
  {
    path: '/activities',
    name: 'Activities',
    meta:{auth_required: true},
    component: ActivityPage
  },
  {
    path: '/members',
    name: 'MembersPage',
    meta:{auth_required: true},
    component: MembersPage
  },
  {
    path: '/qualactivities',
    name: 'QualActivitiesPage',
    meta:{auth_required: true},
    component: QualActivitiesPage
  },
  {
    path: '/about',
    name: 'About',
    meta:{auth_required: false},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/auth',
    name:'Auth',
    component: AuthPage
  
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{

  if(to.meta?.auth_required){
    if(localStorage.getItem('token')){
      next()
    }
    else{
      next({path: '/auth'})
    }
  }else{
    next()
  }


  next()
  
})

export default router
