











import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import GlobalModule from "@/store/modules/global/global.store";
import { GlobalGetters } from "@/store/modules/global/global.types";
import MessageOverlay from "../components/ui-components/MessageOverlay.vue";
import SidebarModule from "@/store/modules/sidebar/sidebar.store";
import { SidebarActions } from "@/store/modules/sidebar/sidebar.types";

@Component({
  components: { MessageOverlay },
})
export default class AuthPage extends Vue {
  public globalModule = getModule(GlobalModule, this.$store);
  public globalGetters = GlobalGetters;
  public token = "";
  public sidebarModule: SidebarModule = getModule(SidebarModule, this.$store)


  async mounted() {
    this.$nextTick(() => {
      this.token = this.$route.query.token as string;
      this.validate_token();
    });
  }

  async validate_token() {
    await this.globalModule.AUTHENTICATE_TOKEN(this.token);

    if (this.globalModule[GlobalGetters.GET_IS_AUTHENTICATED]) {
      if (localStorage.getItem("token") == null) {
        localStorage.setItem("token", this.token);
      }

      await this.sidebarModule[SidebarActions.FETCH_SERVERS]();

      window.location.href = "/";
    }
  }
}
