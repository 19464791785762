




































































































import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SidebarModule from "@/store/modules/sidebar/sidebar.store";
import { SidebarActions, SidebarGetters } from "@/store/modules/sidebar/sidebar.types";
import Segment from "@/types/Segment";
import ExportModule from "@/store/modules/exports/export.store";
import GlobalModule from '@/store/modules/global/global.store';
import TablesModule from '@/store/modules/tables/tables.store';
import { TablesActions } from '@/store/modules/tables/tables.types';
import {
  ExportActions,
  ExportGetters,
} from "@/store/modules/exports/export.type";
import DropDownItem from "@/types/DropDownItem";
import { DEFAULT_SEGMENTS } from "@/services/constants";

@Component({})
export default class RawDataExport extends Vue {
  public showmodal: boolean = false;
  public raw_data_export_url: string | null = null;

  public sidebarModule = getModule(SidebarModule, this.$store);

  public sidebarGetters = SidebarGetters;
  public globalModule: GlobalModule = getModule(GlobalModule, this.$store)
  public tableModule: TablesModule = getModule(TablesModule, this.$store)


  public GET_FILTERED_SQUARE_SEGMENT_LIST: Array<Segment> | null = [];

  public selected_segments: Array<Segment> = [];

  public start_date: string | null =
    this.sidebarModule[SidebarGetters.GET_SQUARE_START_DATE];
  public end_date: string = new Date().toISOString().substring(0, 10);

  public exportModule = getModule(ExportModule, this.$store);
  public exportGetters = ExportGetters;

  created() {
    this.selected_segments.push(DEFAULT_SEGMENTS[0]);
    this.start_date = this.sidebarModule[SidebarGetters.GET_SQUARE_START_DATE];
  }

  mounted(){
    document.addEventListener('click',  () => {
      this.downloaded();
      }, false);
  }

  async downloaded() {
    await this.exportModule[ExportActions.RESET_EXPORT_URL]();
  }

  async selectSegments(value) {
    this.selected_segments = value;
  }

  async removeOption(value) {
    const filtered_segments = _.filter(this.selected_segments, (el) => {
      return el.value != value.value;
    });

    this.selected_segments = filtered_segments;
  }

  async selectSegment(value) {
    this.selected_segments.push(value);
  }

  filterilterSegment(segments: Array<Segment>) {
    return [DEFAULT_SEGMENTS[0], segments.slice(2)].flat();
  }
  async export_data() {

    if(this.sidebarGetters.GET_SELECTED_SQUARES.length > 0 ){
      this.sidebarModule[SidebarActions.CREATE_SESSION_ID]();
      await this.tableModule[TablesActions.RESET_TABLES]();
      await this.sidebarModule[SidebarActions.FETCH_DATA]();
    }

    const server: DropDownItem | null =
      this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER];
    const client: DropDownItem | null =
      this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT];
    const exportSegments =
      this.selected_segments.length > 0
        ? this.selected_segments.map((el) => el.title)
        : ["None"];

    const export_payload = {
      id: this.sidebarModule[SidebarGetters.GET_SESSION_ID],
      serverId: server?.value,
      clientCode: client?.value,
      export_segments: exportSegments,
      export_start_date:
        this.start_date == null
          ? this.sidebarModule[SidebarGetters.GET_SQUARE_START_DATE]
          : this.start_date,
      export_end_date: this.end_date,
    };

    await this.exportModule[ExportActions.CREATE_RAW_DATA_EXPORT](
      export_payload
    );

    this.showmodal = !this.showmodal;
  }
}
