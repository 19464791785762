


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
// import _ from 'lodash'

@Component({components:{vSelect}})
export default class ISCMultSelect extends Vue{
  @Prop() mutualExclusive
  @Prop() options
  @Prop() label
  @Prop() title
  @Prop() selected
  @Prop() mutltiple
  @Prop() isOptional
  @Prop() selectedValues
  // @Prop() defaultSegment

  // public selected_options: Array<any>= []
  public value =  null

  removeOption = option =>{

      this.$emit('removeOption', option)
      this.value=null

  }

  alreadyExists(list, filter_value, label){
    if(typeof filter_value =='object'){

      if(list){
         const filtered_list = list.filter(el => el[label] == filter_value[label])

        if(filtered_list.length ==0){
          return false
        }
        else{
          return true
        }
      }else{
        return false
      }




      }else{
        return list.includes(filter_value)
      }
  }

  @Watch('value', {immediate: true})
  select(next){
    if(this.value){
      // if(next.value == this.mutualExclusive){
      //   this.selected_options = [next]
      // }else{

      //   if(this.selected_options.length >0){
      //     if(this.selected_options[0].value == this.mutualExclusive){
      //       this.selected_options=[]
      //     }
      //   }
      //   this.selected_options.push(this.value)
      // }

      const isInList = this.alreadyExists(this.selectedValues, next, this.label)

      console.log('The value of isInList it used to decide to execute or not => ', isInList);
      console.log('This is selected values  sent from props in multi selct component=> ', this.selectedValues);

      console.log('This is what is sent put  => \n', next);

      next

          if(!isInList){

            this.$emit('applied',next)
          }

    }
  }
}
