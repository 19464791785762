import DropDownItem from "@/types/DropDownItem";
import Table, { Filter } from "@/types/Table";
import { filter } from "vue/types/umd";
import { filiterData } from "../service";

export const formartTable = (data: Array<any>, exporturl: string, colummap: any, column_map_hover, column_type: any) : Table=> {

    const notEmpty = data && data.length;
    const keys: Array<string> = notEmpty > 0 ? Object.keys(data[0]) : Object.keys(colummap)


    const headers:Array<any> = []

    keys.map((key: string) =>{

        headers.push({
            text:key,
            align: 'start',
            sortable: true,
            value: key,
            filterable:true,
            divider: true,
            name: key,
            width: '72px',
            filtertype: colummap[key],
            hover_desc: column_map_hover ? column_map_hover[key]: null,
            column_type: column_type[key]
        })
    })

    const col = keys[0];
    const tableData = data ? data : [{col: 'No data availabddle'}];

    const table: Table  = {
        headers:headers,
        data: tableData,
        exporturl:exporturl,
        filteredData: data,
    }

    return table

}

export const singleTableFilter = (filter:Filter, data:Array<any>): Array<any> =>{



    // filters.map((flt: Filter) =>{

        const filterValues: Array<any> = []

        filter.filters.map((el:DropDownItem) => filterValues.push(el.value) )
    //     const filteredData = filiterData(flt.filterkey,filterValues, data)
    // })
    // const filteredData = filiterData(flt.filterkey,filterValues, data)
    return filiterData(filter.filterkey,filterValues, data)
}

export const multiTableFilter =(filters:Array<Filter>, data:Array<any>)=>{

    let filtredDataTable  =data

    filters.map((filter:Filter) =>{

        filtredDataTable = singleTableFilter(filter, filtredDataTable)
    })

    return filtredDataTable


}

