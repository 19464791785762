





















import { Component , Vue, Prop } from "vue-property-decorator";

@Component({})
export default class ColumFilterView extends Vue {

    @Prop() filter

    async removeFilter(filtervalue, filtername){
      this.$emit('removeFilter', {filtervalue, filtername})
    }
}
