export const FilterGetters = {
    GET_QUAL_ACTIVITY_FILTERS:'GET_QUAL_ACTIVITY_FILTERS',
    GET_ACTIVITY_FILTERS:'GET_ACTIVITY_FILTERS',
    GET_COUNTS_FILTERS:'GET_COUNTS_FILTERS',
    GET_KPIS_FILTERS:'GET_KPIS_FILTERS'
}

export const  FiltersActions = {
    ADD_QUAL_ACTIVITY_FILTERS: 'ADD_QUAL_ACTIVITY_FILTERS',
    REMOVE_QUAL_ACTIVITY_FILTERS: 'REMOVE_QUAL_ACTIVITY_FILTERS',
    CLEAR_QUAL_ACTIVITY_FILTERS: 'CLEAR_QUAL_ACTIVITY_FILTERS',
    ADD_ACTIVITY_FILTERS: 'ADD_ACTIVITY_FILTERS',
    REMOVE_ACTIVITY_FILTERS: 'REMOVE_ACTIVITY_FILTERS',
    CLEAR_ACTIVITY_FILTERS: 'CLEAR_ACTIVITY_FILTERS',
    ADD_COUNTS_FILTERS: 'ADD_COUNTS_FILTERS',
    REMOVE_COUNTS_FILTERS: 'REMOVE_COUNTS_FILTERS',
    ADD_KPIS_FILTERS: 'ADD_KPIS_FILTERS',
    REMOVE_KPIS_FILTERS: 'REMOVE_KPIS_FILTERS',
    REMOVE_COUNTS_FILTER: 'REMOVE_COUNTS_FILTER',
    CLEAR_COUNTS_FILTERS: 'CLEAR_COUNTS_FILTERS',
    CLEAR_KPIS_FILTERS: 'CLEAR_KPIS_FILTERS',
    ADD_KPIS_FILTER: 'ADD_KPIS_FILTER',
}

export const  FiltersMutations = {
    SET_QUAL_ACTIVITY_FILTERS: 'SET_QUAL_ACTIVITY_FILTERS',
    SET_ACTIVITY_FILTERS: 'SET_ACTIVITY_FILTERS',
    SET_COUNTS_FILTERS: 'SET_COUNTS_FILTERS',
    SET_KPIS_FILTERS: 'SET_KPIS_FILTERS'
}