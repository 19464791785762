












import { Component, Prop, Vue } from "vue-property-decorator";
import Spinner from '../molecules/Spinner.vue'
@Component({
  components:{Spinner}
})
export default class MessageOverlay extends Vue {
  @Prop() text!: string;
  @Prop() timer!: boolean;
  public second: string = "00";
  public minutes: string = "00";
  public sec: number = 0;

  pad(val) : string{
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  created() {
    if (this.timer) {
      setInterval(() => {
        ++this.sec;
        this.second = this.pad(this.sec % 60);
        this.minutes = this.pad(parseInt((this.sec / 60).toString()));
      }, 1000);
    }
  }
}
