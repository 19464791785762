




























import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class MultiSelectV2 extends Vue {


    @Prop() options

    @Prop() selected_options

    @Prop() title
    @Prop() label


    async addOption (value) {

        console.log('adding ===== \n', value);

        this.$emit('addOption', value)


    }

    async  removeSquare(value) {
         console.log('remove ===== \n', value);

         this.$emit('removeOption', value)
    }
}
