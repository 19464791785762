
export const downloadfile_from_link = async (link:string, filename: string, tries = 0) =>{

  fetch(link).then((res) => {
    if (res.status == 200) {
      res.blob().then((data) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.target = "_blank";
        a.click();
      });
    } else {
        
      if (tries < 3) {
        downloadfile_from_link(link, filename, tries+1);
      } else {
        // error handle
      }
    }
  });
}

export const downloadCSV = (csv_data, filename) =>{
    
    if (filename == null) filename = "data_export.csv"
  
    if (!csv_data.match(/^data:text\/csv/i)) {
      csv_data = 'data:text/csv;charset=utf-8,' + csv_data;
    }
  
    const data = encodeURI(csv_data);
    
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  
  
  }
  

