





















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DropDownItem from '@/types/DropDownItem';

import 'vue-select/dist/vue-select.css';
import _ from 'lodash'


@Component({})
export default class MultiSelectV2 extends Vue {

    @Prop() options

    @Prop() selected_options!: Array<any> | null

    @Prop() title!:string
    @Prop() label!: string

    @Prop() selectAllOption

    public show_checkbox = false

    public selected : Array<any> | null= this.selected_options

    public selectAll: Boolean = false


    @Watch('selectAll')
    selectAllToggle(next){

      const temp: Array<DropDownItem> = []

      if(next){


        this.options.map((el:DropDownItem) =>{

          if(!el.mutaulExclusive){
            temp.push(el)
          }
        })
      }

      this.selected = temp

       this.$emit('applied', this.selected)


    }

    @Watch('selected')
    select(next, prev){

      if(prev){

        if(prev.length > 0){
          if(prev[0].mutaulExclusive){
            this.selected = _.filter(next, e => !e.mutaulExclusive)
          }else{

            const withMutualExclusivePrev = _.find(prev, e => e.mutaulExclusive)
            const withMutualExclusiveNext = _.find(next, e => e.mutaulExclusive)

            if(withMutualExclusivePrev == undefined && withMutualExclusiveNext){
              this.selected = [withMutualExclusiveNext]
            }

          }
        }
      }

      this.$emit('applied', this.selected)


    }



}
