<template>
  <div class="mt-2">
   
    <label class="black--text font-weight-bold" for="from_date"> {{ title }}</label><br>
    <input class="mt-2" :name="name"  :max="maxDate" v-model="value" type="date" />
  </div>
</template>

<script>
import { ref, watch} from '@vue/composition-api';
export default {

  setup(props, {emit}){
  
    const value = ref(props.defaultValue)
   
    watch(value, () =>{
      emit("applied", value.value);
    })

    return {value}
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    defaultValue:{
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default:  new Date().toISOString().substr(0, 10),
    }
  },

};  
</script>

<style>

  input[type="date"]::-ms-clear {
      display: none !important
  }

  input[type="date"]::-webkit-inner-spin-button,    
input[type="date"]::-webkit-clear-button { display: none; }
</style>
