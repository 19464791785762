












































































import { getModule } from 'vuex-module-decorators'
import SidebarModule from '@/store/modules/sidebar/sidebar.store'
import Segment from '@/types/Segment';
import { Component,  Vue } from "vue-property-decorator";
import GlobalModule from '@/store/modules/global/global.store';
import TablesModule from '@/store/modules/tables/tables.store';
import { SidebarGetters } from '@/store/modules/sidebar/sidebar.types';
import _ from 'lodash'
import { DEFAULT_SEGMENTS } from '@/services/constants';

const ActivitySidebarPros = Vue.extend({
      props:{
      button_title: {type: String},
      sidebar_title: {type: String},
      start_date:{type: String}
    }
})

@Component({
  
})
export default class ActivitySidebar extends ActivitySidebarPros{

  public sidebarModule: SidebarModule = getModule(SidebarModule, this.$store)
  public globalModule: GlobalModule = getModule(GlobalModule, this.$store)

  public tableModule: TablesModule = getModule(TablesModule, this.$store)

  public activitySegment = DEFAULT_SEGMENTS[0]

  public startDate: string  = this.start_date

  public endDate: string =new Date().toISOString().substring(0,10)


  public segmentfilt_input: null | undefined | any = null

  public sidebarGetters = SidebarGetters

  created(){

    this.startDate = this.start_date
     
  }

  async removeOption(value){

    const filtered_option = _.filter(this.segmentfilt_input, el => {
        return el.value != value.value
      })


      if(this.segmentfilt_input.length ==1){
        this.segmentfilt_input =[]
      }else{
          this.segmentfilt_input = filtered_option
      }

  

  }
   
  async selectSegment(value:Segment){


    if(this.activitySegment?.value == 'None'){
      this.segmentfilt_input = undefined
    }
    
    this.activitySegment = value
  }

  async selectSegmentOption(value){

    if(this.segmentfilt_input){

      if(value.value =='None'){
        this.segmentfilt_input=[value]
      }else{

        if(this.segmentfilt_input[0].value == 'None'){
          this.segmentfilt_input=[value]

        }else{
           this.segmentfilt_input.push(value)
        }
        
      }
     
    }else{
      this.segmentfilt_input =[value]
    } 
    
  }

  async fetchData(){
   
    const segment_selection = {
                    segment_input: this.activitySegment?.title,
                    segmentfilt_input: this.segmentfilt_input,
                    startDate: this.startDate,
                    endDate: this.endDate
                }

                this.$emit('applied', segment_selection)    
  }
 
}
