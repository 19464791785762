







































































































import { Component, Vue } from "vue-property-decorator";
import SidebarModule from '@/store/modules/sidebar/sidebar.store';
import { getModule } from 'vuex-module-decorators';
import { SidebarGetters, SidebarActions, SidebarActionSelectionDeps } from '@/store/modules/sidebar/sidebar.types';
import { TablesActions } from '@/store/modules/tables/tables.types';
import TablesModule from '@/store/modules/tables/tables.store';
import GlobalModule from '@/store/modules/global/global.store';
import { GlobalGetters, GlobalActions } from '@/store/modules/global/global.types';

@Component({})
export default class GlobalSelection extends Vue {


  public sidebarModule: SidebarModule = getModule(SidebarModule, this.$store)

  public sidebarGetters = SidebarGetters

  public tableModule: TablesModule = getModule(TablesModule, this.$store)

  public globalModule: GlobalModule = getModule(GlobalModule, this.$store)

  public globalGetters = GlobalGetters

  public displayNext = false


  async mounted(){
    if(localStorage.getItem('token') !== null){
      await this.sidebarModule[SidebarActions.FETCH_SERVERS]();
    }
  }

   async selectServer(value) {
    await this.sidebarModule[SidebarActions.SELECT_SERVER](value);

    await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](SidebarActionSelectionDeps[SidebarActions.SELECT_SERVER]);

    await this.tableModule[TablesActions.RESET_TABLES]();

    await this.sidebarModule[SidebarActions.FETCH_CLIENTS]();
  }

   async selectClient(value) {

    await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](SidebarActionSelectionDeps[SidebarActions.SELECT_CLIENT]);

    await this.tableModule[TablesActions.RESET_TABLES]();

    await this.sidebarModule[SidebarActions.SELECT_CLIENT](value);

    await this.sidebarModule[SidebarActions.FETCH_PROGRAMS]();
  }

  async selectProgram(value) {

    this.sidebarModule[SidebarActions.SELECT_PROGRAM](value);
    await this.sidebarModule[SidebarActions.RESET_SIDEBAR_STATE](SidebarActionSelectionDeps[SidebarActions.SELECT_PROGRAM]);

    await this.tableModule[TablesActions.RESET_TABLES]();

    await this.sidebarModule[SidebarActions.FETCH_SQUARES]();
  }

  async addSquare(value) {

    await this.sidebarModule[SidebarActions.ADD_SQUARE](value);
  }

  async applyFilters(){

    this.sidebarModule[SidebarActions.CREATE_SESSION_ID]();

    const selected_squares: Array<number> = [];

    this.sidebarModule[SidebarGetters.GET_SELECTED_SQUARES]?.map(el => selected_squares.push(el.SquareId));
    const time_predictor_payload = {
      serverId: this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER]?.value,
      clientCode: this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT]?.Code,
      squareId: selected_squares
    };

    await this.globalModule[GlobalActions.TIME_ESTIMATOR](time_predictor_payload);

    await this.tableModule[TablesActions.RESET_TABLES]();
    await this.sidebarModule[SidebarActions.FETCH_DATA]();
    this.globalModule.SET_FETCH_DATA_STATE(true);
    this.displayNext = true;

  }
}
