








import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Message extends Vue {
  @Prop() text!: string;

}
