import axios from 'axios'
import http,{post} from '../HttpService'

  
// inputs$serverId <- req$get_param_query("serverId")
// inputs$clientId <- req$get_param_query("clientId")
// inputs$squareId <- req$get_param_query("squareId")

class GlobalApi {

    authenticate(token){
        return post('/services/login', {token})
    }

    fetchtime_estimation(payload){
        return post(`/square/time_prediction`, {...payload, token: localStorage.getItem('token')})
    }
}

export default new GlobalApi()