import DropDownItem from "@/types/DropDownItem";
import Segment from "@/types/Segment";
import Square from "@/types/Sqaure";
import SquareSegment from "@/types/SquareSegment";
import _ from 'lodash'

export const filterSegmentListBySquareId = (squareSegments :Array<SquareSegment> | null, squareId): Array<Segment> => {

  
    const segments: Array<Segment> = []

    const filtredSquareSegments =squareSegments?.filter(el => {
        return el.squareId.toString() == squareId.toString()
    })

    filtredSquareSegments?.map(sq_seg =>{
        sq_seg.Segments.map(seg => segments.push(seg))
    })

    return segments

        
}

export const filterSegmentListBySquareIds = (squareSegments :Array<SquareSegment> | null, selectedSquares: Array<Square> | null): Array<Segment> => {

   
    const squareIds: Array<String> = []
    selectedSquares?.map(el => squareIds.push(el.SquareId.toString()))

    const filtered_square_segments = _.filter(squareSegments, (el:SquareSegment) => {
        
      
        
        return squareIds.includes(el.squareId.toString())
    
    })

    
    
    const segments: Array<Segment> = []

    filtered_square_segments.map(el => {        

        el.Segments.map(seg => segments.push(seg))

    })

       
    return deduplicateListSegmentLIst(segments)

        
}

export const deduplicateListSegmentLIst  = (list:Array<Segment>): Array<Segment> =>{

    const unique = new Set<Segment>()

    list.map(el => unique.add(el))
    
    return Array.from(unique)


}

export const get_start_date =( date_key: string, list) : string=>{

    const sorted_list =_.sortBy(list, el => new Date(el[date_key]))

    return new Date(sorted_list[0].date_min).toISOString().slice(0,10)
        
}

export const formatSquareSegments = (square_id: any, square_segments: any ) =>{

    const  segs = square_segments.map(seg => formatSegment(seg, square_id)) 
    return segs

}

export const formatSegment = (rawSeg: any, square_id: any ): Segment =>{

    const segment_options: Array<DropDownItem> = []

    rawSeg.Segment_options.map(seg => segment_options.push({title: seg, value: (seg == "None") ? "NO_SEGMENT_NULL": seg, mutaulExclusive: (seg == "None") ? true : false}))

        
        return {
            square_id: square_id,
            segmentationId: rawSeg.SegmentationId,
            segment_options,
            value: rawSeg.SegmentationId,
            title: rawSeg.Segment,
            mutaulExclusive: false
        }

}