import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '../..'

import exportApi from "@/services/API/export.api";
import { convert_table_data_to_csv, downloadCSVFile, download_raw_data_export } from "./export.service";
import { ExportActions, ExportGetters, ExportMutations } from "./export.type";

import GlobalModule from "../global/global.store";
import { GlobalActions } from "../global/global.types";

@Module({
    name: 'ExportModule',
    namespaced: true,
    stateFactory: true,
    dynamic: true,
    store
})

class ExportModule extends VuexModule {

    // public sidebarModule = getModule(SidebarModule, store)
    public globalModule = getModule(GlobalModule, store)

    public raw_data_export_url: string| null =null

    @Action
    async [ExportActions.EXPORT_TABLE_DATA_TO_CSV](payload){
       
        const {datatable, caption} = payload

        const tableCSVdata = convert_table_data_to_csv(datatable.headers, datatable.data)

        
        downloadCSVFile(tableCSVdata, `${caption}.csv`)
        
    }
    
    @Action
    async [ExportActions.CREATE_RAW_DATA_EXPORT](payload){

        this.globalModule[GlobalActions.LOAD_API]()

       
        try {
            const res = await exportApi.fetchRawDataExportURL(payload)
            // const res = await HttpService.post('/square/raw_data_export',payload)

            if(res.data.errmsg){
                this.globalModule.LOAD_ERROR(res.data.errmsg[0])
            }
            else{
                const export_url: string =res.data[0]

                download_raw_data_export(export_url)
        
                this.context.commit(ExportMutations.SET_RAW_DATA_EXPORT_URL, export_url)
        
                this.globalModule[GlobalActions.CONCLUDE_API_CALL]()
            }

        } catch (error) {

            console.log('Error ===== > \n',error);
            
             this.globalModule.LOAD_ERROR(error)
        }
        
       
        
    }

    @Action
    [ExportActions.RESET_EXPORT_URL](){
        this.context.commit(ExportMutations.SET_RAW_DATA_EXPORT_URL, null)
        
    }

    @Mutation
    [ExportMutations.SET_RAW_DATA_EXPORT_URL](export_url: string| null): void{
        this.raw_data_export_url = export_url
    }

    get [ExportGetters.GET_RAW_DATA_EXPORT_URL]():string | null{
        return this.raw_data_export_url
    }




}

export default ExportModule