import Segment from "@/types/Segment"

export const NO_SEGMENT_NULL = "NO_SEGMENT_NULL"

export const DEFAULT_SEGMENTS: Array<Segment> = [
    {
        square_id: null,
        title:'None', 
        value:'None', 
        segment_options:null,
        mutaulExclusive: true, 
        segmentationId: 111111111111111111111},

    {
        square_id: null,
        title:'Recruitment partner', 
        value:'Recruitment_Partner', 
        mutaulExclusive: true, 
        segment_options:null, 
        segmentationId: 222222222222222222222222
    }
]
