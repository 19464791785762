import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '../..'
import { FilterGetters, FiltersActions, FiltersMutations } from "./filters.types";
import { Filter } from "./types";
import _ from 'lodash'

@Module({
  name: 'FiltersModule',
  namespaced: true,
  stateFactory: true,
  dynamic: true,
  store
})

class FiltersModule extends VuexModule {

  public qualActivitiesFilters: Array<Filter> = []
  public activitiesFilters: Array<Filter> = []
  public countsFilters: Array<Filter> = []
  public kpisFilters: Array<Filter> = []

  @Action
  async [FiltersActions.CLEAR_QUAL_ACTIVITY_FILTERS]() {

    this.context.commit(FiltersMutations.SET_QUAL_ACTIVITY_FILTERS, [])
  }

  @Action
  async [FiltersActions.CLEAR_KPIS_FILTERS]() {

    this.context.commit(FiltersMutations.SET_KPIS_FILTERS, [])
  }

  @Action
  async [FiltersActions.CLEAR_ACTIVITY_FILTERS]() {

    this.context.commit(FiltersMutations.SET_ACTIVITY_FILTERS, [])
  }

  @Action
  async [FiltersActions.CLEAR_COUNTS_FILTERS]() {

    this.context.commit(FiltersMutations.SET_COUNTS_FILTERS, [])
  }

  @Action
  async [FiltersActions.REMOVE_QUAL_ACTIVITY_FILTERS](filter) {

    const filterToUpdate = _.find(this.qualActivitiesFilters, (el: Filter) => el.filtername == filter.filtername)

    if (filterToUpdate) {
      const options = _.remove(filterToUpdate.filterValues, (el => el != filter.filtervalue))

      const temp: Array<Filter> = []


      this.qualActivitiesFilters.map((el: Filter) => {

        if (el.filtername == filter.filtername) {

          if (options.length > 0) {
            temp.push({ filtername: filter.filtername, filterValues: options, type: filter.type })
          }

        } else {
          temp.push(el)
        }

      })

      this.context.commit(FiltersMutations.SET_QUAL_ACTIVITY_FILTERS, temp)
    } else {
      console.log('removing the last one filter');

    }


  }

  @Action
  async [FiltersActions.REMOVE_ACTIVITY_FILTERS](filter) {


    const filterToUpdate = _.find(this.activitiesFilters, (el: Filter) => el.filtername == filter.filtername)

    if (filterToUpdate) {
      const options = _.remove(filterToUpdate.filterValues, (el => el != filter.filtervalue))

      const temp: Array<Filter> = []


      this.activitiesFilters.map((el: Filter) => {

        if (el.filtername == filter.filtername) {

          if (options.length > 0) {
            temp.push({ filtername: filter.filtername, filterValues: options, type: filter.type })
          }

        } else {
          temp.push(el)
        }

      })

      this.context.commit(FiltersMutations.SET_ACTIVITY_FILTERS, temp)
    } else {
      console.log('removing the last one filter');

    }


  }

  @Action
  async [FiltersActions.REMOVE_COUNTS_FILTER](filter) {


    const filterToUpdate = _.find(this.countsFilters, (el: Filter) => el.filtername == filter.filtername)

    if (filterToUpdate) {
      const options = _.remove(filterToUpdate.filterValues, (el => el != filter.filtervalue))

      const temp: Array<Filter> = []


      this.countsFilters.map((el: Filter) => {

        if (el.filtername == filter.filtername) {

          if (options.length > 0) {
            temp.push({ filtername: filter.filtername, filterValues: options, type: filter.type })
          }

        } else {
          temp.push(el)
        }

      })

      this.context.commit(FiltersMutations.SET_COUNTS_FILTERS, temp)
    } else {
      console.log('removing the last one filter');

    }


  }

  @Action
  async [FiltersActions.REMOVE_KPIS_FILTERS](filter) {


    const filterToUpdate = _.find(this.kpisFilters, (el: Filter) => el.filtername == filter.filtername)

    if (filterToUpdate) {
      const options = _.remove(filterToUpdate.filterValues, (el => el != filter.filtervalue))

      const temp: Array<Filter> = []


      this.kpisFilters.map((el: Filter) => {

        if (el.filtername == filter.filtername) {

          if (options.length > 0) {
            temp.push({ filtername: filter.filtername, filterValues: options, type: filter.type })
          }

        } else {
          temp.push(el)
        }

      })

      this.context.commit(FiltersMutations.SET_KPIS_FILTERS, temp)
    } else {
      console.log('removing the last one filter');

    }


  }


  @Action
  async [FiltersActions.ADD_KPIS_FILTER](payload) {

    console.log('Adding counts filters actions with payload ========== \n', payload);


    if (this.kpisFilters.length == 0) {

      const filter: Filter = {
        filtername: payload.filtername,
        type: payload.type,
        filterValues: [payload.filterValue]
      }


      this.context.commit(FiltersMutations.SET_KPIS_FILTERS, [filter])
    } else {

      let temp: Array<Filter> = this.kpisFilters

      const currentFIlter = _.find(this.kpisFilters, (el: Filter) => el.filtername == payload.filtername)

      if (currentFIlter) {
        temp = []

        this.kpisFilters.map((flt: Filter) => {

          const filterVals = currentFIlter.filterValues

          filterVals.push(payload.filterValue)

          if (flt.filtername == payload.filtername) {
            temp.push({ ...flt, filterValues: filterVals })
          } else {
            temp.push(flt)
          }
        })
      } else {
        // temp.push(filter)

        const filter: Filter = {
          filtername: payload.filtername,
          type: payload.type,
          filterValues: [payload.filterValue]
        }

        temp.push(filter)
      }


      this.context.commit(FiltersMutations.SET_KPIS_FILTERS, temp)
    }
  }

  @Action
  async [FiltersActions.ADD_COUNTS_FILTERS](payload) {

    console.log('Adding counts filters actions with payload ========== \n', payload);


    if (this.countsFilters.length == 0) {

      const filter: Filter = {
        filtername: payload.filtername,
        type: payload.type,
        filterValues: [payload.filterValue]
      }


      this.context.commit(FiltersMutations.SET_COUNTS_FILTERS, [filter])
    } else {

      let temp: Array<Filter> = this.countsFilters

      const currentFIlter = _.find(this.countsFilters, (el: Filter) => el.filtername == payload.filtername)

      if (currentFIlter) {
        temp = []

        this.countsFilters.map((flt: Filter) => {

          const filterVals = currentFIlter.filterValues

          filterVals.push(payload.filterValue)

          if (flt.filtername == payload.filtername) {
            temp.push({ ...flt, filterValues: filterVals })
          } else {
            temp.push(flt)
          }
        })
      } else {
        // temp.push(filter)

        const filter: Filter = {
          filtername: payload.filtername,
          type: payload.type,
          filterValues: [payload.filterValue]
        }

        temp.push(filter)
      }


      this.context.commit(FiltersMutations.SET_COUNTS_FILTERS, temp)
    }
  }

  @Action
  async [FiltersActions.ADD_QUAL_ACTIVITY_FILTERS](payload) {

    if (this.qualActivitiesFilters.length == 0) {

      const filter: Filter = {
        filtername: payload.filtername,
        type: payload.type,
        filterValues: [payload.filterValue]
      }


      this.context.commit(FiltersMutations.SET_QUAL_ACTIVITY_FILTERS, [filter])
    } else {

      let temp: Array<Filter> = this.qualActivitiesFilters

      const currentFIlter = _.find(this.qualActivitiesFilters, (el: Filter) => el.filtername == payload.filtername)

      if (currentFIlter) {
        temp = []

        this.qualActivitiesFilters.map((flt: Filter) => {

          const filterVals = currentFIlter.filterValues

          filterVals.push(payload.filterValue)

          if (flt.filtername == payload.filtername) {
            temp.push({ ...flt, filterValues: filterVals })
          } else {
            temp.push(flt)
          }
        })
      } else {
        // temp.push(filter)

        const filter: Filter = {
          filtername: payload.filtername,
          type: payload.type,
          filterValues: [payload.filterValue]
        }

        temp.push(filter)
      }


      this.context.commit(FiltersMutations.SET_QUAL_ACTIVITY_FILTERS, temp)
    }
  }

  @Action
  async [FiltersActions.ADD_ACTIVITY_FILTERS](payload) {

    if (this.activitiesFilters.length == 0) {

      const filter: Filter = {
        filtername: payload.filtername,
        type: payload.type,
        filterValues: [payload.filterValue]
      }


      this.context.commit(FiltersMutations.SET_ACTIVITY_FILTERS, [filter])
    } else {

      let temp: Array<Filter> = this.activitiesFilters

      const currentFIlter = _.find(this.activitiesFilters, (el: Filter) => el.filtername == payload.filtername)

      if (currentFIlter) {
        temp = []

        this.activitiesFilters.map((flt: Filter) => {

          const filterVals = currentFIlter.filterValues

          filterVals.push(payload.filterValue)

          if (flt.filtername == payload.filtername) {
            temp.push({ ...flt, filterValues: filterVals })
          } else {
            temp.push(flt)
          }
        })
      } else {
        // temp.push(filter)
        const filter: Filter = {
          filtername: payload.filtername,
          type: payload.type,
          filterValues: [payload.filterValue]
        }

        temp.push(filter)
      }


      this.context.commit(FiltersMutations.SET_ACTIVITY_FILTERS, temp)
    }
  }


  @Mutation
  [FiltersMutations.SET_QUAL_ACTIVITY_FILTERS](filters: Array<Filter>) {
    this.qualActivitiesFilters = filters
  }

  @Mutation
  [FiltersMutations.SET_ACTIVITY_FILTERS](filters: Array<Filter>) {
    this.activitiesFilters = filters
  }

  @Mutation
  [FiltersMutations.SET_COUNTS_FILTERS](filters: Array<Filter>) {
    this.countsFilters = filters
  }

  @Mutation
  [FiltersMutations.SET_KPIS_FILTERS](filters: Array<Filter>) {
    this.kpisFilters = filters
  }


  get [FilterGetters.GET_QUAL_ACTIVITY_FILTERS](): Array<Filter> | null {
    return this.qualActivitiesFilters
  }

  get [FilterGetters.GET_ACTIVITY_FILTERS](): Array<Filter> | null {
    return this.activitiesFilters
  }

  get [FilterGetters.GET_COUNTS_FILTERS](): Array<Filter> | null {
    return this.countsFilters
  }

  get [FilterGetters.GET_KPIS_FILTERS](): Array<Filter> | null {
    return this.kpisFilters
  }
}

export default FiltersModule
