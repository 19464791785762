import axios from "axios";
import {post} from "../HttpService"

class ExportApi{


    fetchRawDataExportURL(payload){

        return post('/square/raw_data_export',{...payload, token: localStorage.getItem('token')})

    }
}

export default new ExportApi()