import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '../..'
import GlobalModule from "../global/global.store";
import Table from "@/types/Table";
import {formartTable} from "@/services/tables/table.service";
import tableApi from "@/services/API/table.api";
import { TablesActions, TablesGetters, TablesMutations } from "./tables.types";
// import DropDownItem from "@/types/DropDownItem";
import { GlobalActions } from "../global/global.types";
import sidebarApi from "@/services/API/sidebar.api";
import { DEFAULT_SEGMENTS } from "@/services/constants";


@Module({
    name: 'TablesModule',
    namespaced: true,
    stateFactory: true,
    dynamic: true,
    store
})
class TablesModule extends VuexModule{

    public globalModule = getModule(GlobalModule, store)
    public countsTable: Table | null =null
    public kpisTable: Table | null = null
    public activitiesTable: Table | null = null;
    public qualActivitiesTable: Table | null = null;
    private dropColumns: string[] = [ "No Segment Split"];

    @Action
    async [TablesActions.RESET_TABLES](){

        for(const mutation in TablesMutations){
        
           this.context.commit(mutation, null)
            
        }
    }

    @Action
    async [TablesActions.FETCH_ACTIVITTIES_TABLE](payload){

       
        this.globalModule[GlobalActions.LOAD_API]()
       
        const body ={
            ...payload,
            segment_input: (payload.segment_input ==undefined) ? DEFAULT_SEGMENTS[0].value: payload.segment_input,
            segmentfilt_input: (payload.segmentfilt_input == undefined || payload.segment_input == "None") ? 'NO_SEGMENT_NULL' : payload.segmentfilt_input.map(el => el.value)
        }
        if(body.segment_input.toLowerCase() == "recruitment partner"){
            body.segment_input = "Recruitment_Partner";
        }
        if(body.segment_input == ""){
            body.segment_input = "None";
        }
        try {
           

            const res = await tableApi.fetchActivitiesTable(body)

            if(res.data.errmsg){
                this.globalModule.LOAD_ERROR(res.data.errmsg[0])
            }
            else{

                const Activities = res.data.Activities ? res.data.Activities : [];

                const { activities_exporturl, column_map_act_filt, column_map_act_type ,column_map_act_hover} = res.data
               
                Activities.map((activity:any) => {
                     this.dropColumns.forEach((col) => {delete activity[col]});
                });

                const activitiessTable: Table = formartTable(Activities, activities_exporturl, column_map_act_filt[0], column_map_act_hover[0], column_map_act_type[0])
                this.context.commit(TablesMutations.SET_ACTVITIES_TABLE, activitiessTable)           
           
                this.globalModule.CONCLUDE_API_CALL()
            }
            

            this.globalModule.CONCLUDE_API_CALL
        } catch (error) {
          
            this.globalModule.LOAD_ERROR(error)
            
        }        
    }

    @Action
    async [TablesActions.FETCH_QUAL_ACTIVITTIES_TABLE](payload){


    
        this.globalModule.LOAD_API()
        const body ={
            ...payload,
            segment_input: (payload.segment_input ==undefined) ? DEFAULT_SEGMENTS[0].value: payload.segment_input,
            segmentfilt_input: (payload.segmentfilt_input == undefined || payload.segment_input == "None") ? 'NO_SEGMENT_NULL' : payload.segmentfilt_input.map(el => el.value)
        }

        if(body.segment_input.toLowerCase() == "recruitment partner"){
            body.segment_input = "Recruitment_Partner";
        }
        if(body.segment_input == ""){
            body.segment_input = "None";
        }
     
        try {
            this.globalModule.LOAD_API()
            const res = await tableApi.fetchQualActivtiesTable(body)

            if(res.data.errmsg){
                this.globalModule.LOAD_ERROR(res.data.errmsg[0])
            }
            else{

                const {Qual_Activities, qual_activities_exporturl, column_map_qualact_filt, column_map_qualact_type, column_map_qualact_hover} = res.data


                Qual_Activities.map((activity:any) => {
                    this.dropColumns.forEach((col) => {delete activity[col]});
                });
                
                const activitiesTable: Table = formartTable(Qual_Activities, qual_activities_exporturl, column_map_qualact_filt[0], column_map_qualact_hover[0], column_map_qualact_type[0])    
                this.context.commit(TablesMutations.SET_QUAL_ACTIVITIES, activitiesTable)
           
                

                
                this.globalModule.CONCLUDE_API_CALL()
            }
        } catch (error) {
            
            this.globalModule.LOAD_ERROR(error)
            
        }
        
    }



    @Action
    async [TablesActions.FETCH_COUNTS_KPIS_TABLE](payload){

        this.globalModule.LOAD_API()   

        try {

            const body ={
                ...payload,
                segment_input: (payload.segment_input ==undefined) ? DEFAULT_SEGMENTS[0].value: payload.segment_input,
                segmentfilt_input: (payload.segmentfilt_input == undefined) || payload.segmentfilt_input == "NO_SEGMENT_NULL" ? 'NO_SEGMENT_NULL' : (payload.segmentfilt_input == 'NO_SEGMENT_NULL') ? payload.segmentfilt_input  : payload.segmentfilt_input.map(el => el.value)
            }
           
            if(body.segment_input.toLowerCase() == "recruitment partner"){
                body.segment_input = "Recruitment_Partner";
            }
            if(body.segment_input == ""){
                body.segment_input = "None";
            }
            
            const res = await sidebarApi.fetchCountsKpis(body)

            if(res.data.errmsg){
                this.globalModule.LOAD_ERROR(res.data.errmsg[0])
            }
            else{

              
                const {Counts, counts_exporturl, KPIs, 
                    kpis_exporturl, column_map_counts_filt, column_map_counts_type,
                    column_map_counts_hover, column_map_kpis_filt, column_map_kpis_type,
                    column_map_kpis_hover
                } = res.data

                console.log(res.data);
                console.log('url counts: ', counts_exporturl);
                console.log('url kpis: ', kpis_exporturl);

                Counts.map((count:any) => {
                    this.dropColumns.forEach((col) => {delete count[col]});
                });

                KPIs.map((kpi:any) => {
                    this.dropColumns.forEach((col) => {delete kpi[col]});
                });

                const countsTable: Table = formartTable(Counts, counts_exporturl, column_map_counts_filt[0], column_map_counts_hover[0], column_map_counts_type[0])
                this.context.commit(TablesMutations.SET_COUNTS_TABLE, countsTable)

                const kpisTable: Table = formartTable(KPIs, kpis_exporturl, column_map_kpis_filt[0], column_map_kpis_hover[0], column_map_kpis_type[0])
                this.context.commit(TablesMutations.SET_KPIS_TABLE, kpisTable)
                
                this.globalModule.CONCLUDE_API_CALL()
            }
        } catch (error) {

            console.log('error: ', error)
            this.globalModule.LOAD_ERROR(error)
        }
    }

    @Mutation
    [TablesMutations.SET_QUAL_ACTIVITIES](table: Table | null): void {

        this.qualActivitiesTable = table
    }

    @Mutation
    [TablesMutations.SET_ACTVITIES_TABLE](table: Table | null): void{
       
        this.activitiesTable = table
    }

    @Mutation
    [TablesMutations.SET_COUNTS_TABLE](table: Table | null):void{
        this.countsTable = table
    }

    @Mutation
    [TablesMutations.SET_KPIS_TABLE](table: Table | null): void{
        this.kpisTable = table
    }

    get [TablesGetters.GET_COUNTS_TABLE](): Table | null{
        return this.countsTable
    }

    get [TablesGetters.GET_KPIS_TABLE](): Table | null {
        return this.kpisTable
    }

    get [TablesGetters.GET_ACTIVITIES_TABLES](): Table | null{
        return this.activitiesTable
    }

    get [TablesGetters.GET_QUAL_ACTIVITIES_TABLES](): Table | null{
        return this.qualActivitiesTable
    }


}

export default TablesModule