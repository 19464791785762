
























import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class SingleSelectV2 extends Vue {

    @Prop() options

    @Prop() selected_option

    @Prop() title
    @Prop() label

    @Prop() clearable

    public selectOption(value){

        this.$emit('applied',value )
    }

}
