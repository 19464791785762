<template>
  <div class="text-center">

    <div v-if="single">
      <span color="success" v-if="selected">{{selected[label]}}</span>
    </div>
    <div v-else>

      <span v-for="item in selected" :key="item[label]">
        {{item[label]}}
      </span>

    </div>
   
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{title}}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(option, index) in options"
          :key="index">

          <v-list-item-title @click="select(option)">{{option[label]}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>


<script>
  export default {
    data: () => ({
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
    }),
    props:{
      options: {
        type: Array
      },
      label:{
        type: String
      },
      title:{
        type: String

      },
      selected: {
        type: Object
      },
      single:{
        type: Boolean,
        default: true
      }
    },
    methods:{
      select(item){
     

        this.$emit('applied', item)
      }
    }
  }
</script>