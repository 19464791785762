var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.datatable)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"col":"8"}},[(_vm.datatable)?_c('isc-downloader',{key:_vm.caption,attrs:{"exporturl":_vm.datatable.exporturl},on:{"applied":_vm.exportDataToCSV}}):_vm._e()],1)],1),_c('div',{staticClass:"search"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":("Search in " + _vm.caption),"single-line":"","hide-details":"","color":"#004750"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.showColumnDescription && _vm.onHoverHeader)?_c('v-card',{staticClass:"mt-4 mb-3 pl-2 pr-2 pt-2 columnDescription",attrs:{"elevation":10}},[_vm._v(" "+_vm._s(_vm.onHoverHeader.hover_desc)+" ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"caption":_vm.caption,"calculate-widths":false,"headers":_vm.datatable.headers,"items":_vm.filteredTableData,"search":_vm.search,"items-per-page":_vm.datatable_page_limit},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(head){return _c('th',{key:head.text,staticClass:"tableHead"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',{staticClass:"ml-2 mt-2 mb-2 filter_row"},[_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openFilter(head)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(head.hover_desc)?_c('v-icon',_vm._g(_vm._b({staticClass:"info_icon"},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(head.hover_desc))])])],1)]}}],null,true)},[(_vm.filterValuesOptions.length > 1)?_c('div',{key:head.text,staticClass:"filters"},[_c('TableFilter',{attrs:{"filterOptions":_vm.filterValuesOptions,"title":("Filter by " + (head.value)),"filterKey":head.value,"head":head,"filter":_vm.getCurrentFilter(head.value)},on:{"addRange":function (value) { return _vm.addRange(value, head); },"addFilter":_vm.addFilter,"removeFilter":_vm.removeFilter}})],1):_vm._e()])],1)})}},{key:"item",fn:function(props){return (props.item)?[_c('tr',{staticClass:"row_style"},_vm._l((props.item),function(item,key){return _c('td',{key:key,staticClass:"p-0 m-0 cusstCol",style:(_vm.styleCol(key, item, _vm.colorCode, props.item))},[_vm._v(" "+_vm._s(_vm.displayCol(item, props.headers, key, props.item))+" ")])}),0)]:undefined}}],null,true)})],1):_c('div',[_c('p',[_vm._v("No data available")])])])}
var staticRenderFns = []

export { render, staticRenderFns }