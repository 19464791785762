
























import { Component, Prop, Vue } from "vue-property-decorator";
// import DropDownItem from '@/types/DropDownItem';

@Component({})
export default class AddRangeFilter extends Vue {

    @Prop() filter

    @Prop() head

    public min = 0

    public max =  1000

    public range: Array<any> =[0, 1000]
    
  

    async addRange (){

       const filter = {filtername: this.head.value, filterValue: {min: this.range[0], max: this.range[1]}, type:this.head.filtertype }

        this.$emit('addFilter', filter)
        
    }

    async removeFilter(value){
       
        this.$emit('removeFilter', value)


    }
}
