
import {post} from "../HttpService";

class TableApi {

    fetchActivitiesTable(body){
        
        return post('/square/activities', {...body, token: localStorage.getItem('token')})
    }

    fetchQualActivtiesTable(body){

        
        return post('/square/qual_activities',{...body, token: localStorage.getItem('token')})
    }
}

export default new TableApi()