import DashboardSidebar from './components/Sidebars/DashboardSidebar.vue'
import ActivitiesSidebar from './components/Sidebars/ActivitiesSidebar.vue'
import Dropdown from './components/menus/Dropdown.vue'
import Datepicker from './components/ui-components/Datepicker.vue'
import PopUpModal from './components/ui-components/PopUpModal.vue'
import BodyHeadBar from './components/molecules/BodyHeadBar.vue'
import AddRangeFilter from './components/molecules/AddRangeFilter.vue'
import AddDateRangeFilter from './components/molecules/AddDateRangeFilter.vue'
import AddPointFilter from './components/molecules/AddPointFilter.vue'
import ColumFilterView from './components/molecules/ColumFilterView.vue'
import Downloader from './components/molecules/Downloader.vue'
import GlobalSelection from './components/molecules/GlobalSelection.vue'
import IscDatatable from './components/molecules/IscDatatable.vue'
import DashboardSelection from './components/molecules/DashboardSelection.vue'
import ActivitySelection from './components/molecules/ActivitySelection.vue'
import FiltersView from './components/molecules/FiltersView.vue'
import KPIsDatatable from './components/molecules/KPIsDatatable.vue'
import FilterableDatatable from './components/molecules/FilterableDatatable.vue'
import ISCSingleSelect from './components/menus/ISCSingleSelect.vue'
import ISCMultiSelect from './components/menus/ISCMultiSelect.vue'
import Navigation from './components/navigations/Navigation.vue'
import Spinner from './components/molecules/Spinner.vue'
import NotificationBar from './components/molecules/NotificationBar.vue'
import RawDataExport from './components/molecules/RawDataExport.vue'
import MultiSelectV2 from './components/menus/MultiSelectV2.vue'
import DropdownCheckbox from './components/menus/DropdownCheckbox.vue'
import SingleSelectV2 from './components/menus/SingleSelectV2.vue'


const createComponent = (name, component) => {
    return { name, component };
  };
  

export default [
    createComponent("dashboard-sidebar", DashboardSidebar),
    createComponent("activities-sidebar", ActivitiesSidebar),
    createComponent("column-filter-view", ColumFilterView),
    createComponent("dropdown", Dropdown),
    createComponent("add-range-filter", AddRangeFilter),
    createComponent("add-date-range-filter", AddDateRangeFilter),
    createComponent("add-point-filter", AddPointFilter),
    createComponent("date-picker", Datepicker),
    createComponent("bodyhead-bar", BodyHeadBar),
    createComponent("isc-downloader", Downloader),
    createComponent("isc-datatable", IscDatatable),
    createComponent("kpis-datatable", KPIsDatatable),
    createComponent("iscfilters-view", FiltersView),
    createComponent("filterable-datatable", FilterableDatatable),
    createComponent("isc-single-select", ISCSingleSelect),
    createComponent("isc-multi-select", ISCMultiSelect),
    createComponent("isc-main-nav", Navigation),
    createComponent("isc-spinner", Spinner),
    createComponent("isc-notification-bar", NotificationBar),
    createComponent("isc-raw-data-export", RawDataExport),
    createComponent("isc-popup", PopUpModal),
    createComponent("dashboard-selection", DashboardSelection),
    createComponent("activity-selection", ActivitySelection),
    createComponent("multi-select-v2", MultiSelectV2),
    createComponent("dropdown-checkbox", DropdownCheckbox),
    createComponent("single-select", SingleSelectV2),
    createComponent("global-selection", GlobalSelection),
]