import { downloadfile_from_link, downloadCSV } from "@/services/filesServices";

export const download_raw_data_export = (url: string) => downloadfile_from_link(url, 'Raw-data-export.xlsx')

export const convert_table_data_to_csv = (headers:any, data:  any) =>{
    let results = ''
    const colDelimiter = ','
    const lineDelimiter = '\n'
    let ctr
  
    const transform_headers: Array<any> = []
    headers.forEach((header:any) => {
    
      if(header.text[0] == "#" ){
  
        const col:any = header.text.substr(1)
      
        transform_headers.push(col.replaceAll("#", " "))
  
      }else{
        transform_headers.push(header.text.replaceAll("#", " "))
      }
    })
    
  
    results += transform_headers.join(colDelimiter)
  
    results+=lineDelimiter
  
    data.forEach(row => {
      ctr = 0
      headers.forEach(header => {
  
        if (ctr > 0) results += colDelimiter;
  
        results += row[header.text];    
        ctr++;
  
      })
      results += lineDelimiter;
    })
  
    return results;
  
  }
  
  export const downloadCSVFile = (csv_data, filename) => downloadCSV(csv_data, filename)
  