






































import { Component , Vue } from "vue-property-decorator";
import GlobalModule from '@/store/modules/global/global.store'
import TablesModule from '@/store/modules/tables/tables.store'
import { getModule } from 'vuex-module-decorators'
import MessageOverlay from '../components/ui-components/MessageOverlay.vue'
import {TablesActions, TablesMutations, TablesGetters} from '@/store/modules/tables/tables.types'
import { GlobalGetters } from '@/store/modules/global/global.types';
import SidebarModule from '@/store/modules/sidebar/sidebar.store';
import { SidebarGetters } from '@/store/modules/sidebar/sidebar.types';
import FiltersModule from '@/store/modules/filters/filters.store';
import { FilterGetters, FiltersActions } from '@/store/modules/filters/filters.types';


@Component({
   components: { MessageOverlay }
})
export default class ActivityPage extends Vue{

  public tablesActions = TablesActions
  public tablesMutations = TablesMutations
  public tablesGetters = TablesGetters

   public globalModule: GlobalModule = getModule(GlobalModule, this.$store)

  public tablesModule: TablesModule = getModule(TablesModule, this.$store)
  public sidebarModule: SidebarModule = getModule(SidebarModule, this.$store)




  public globalGetters = GlobalGetters

  public tableMutator = TablesMutations.SET_ACTVITIES_TABLE


  public start_date = this.sidebarModule[SidebarGetters.GET_ACT_START_DATE]

  public filtersModule: FiltersModule = getModule(FiltersModule, this.$store)

  public filterGetters = FilterGetters

  async fetchActivities (value: { startDate: any; endDate: any; }){

    await this.tablesModule[TablesMutations.SET_ACTVITIES_TABLE](null)
    await this.tablesModule[TablesActions.FETCH_ACTIVITTIES_TABLE]({...value,
    id: this.sidebarModule[SidebarGetters.GET_SESSION_ID],
    act_start_date: value.startDate,
    act_end_date: value.endDate,
    serverId: this.sidebarModule[SidebarGetters.GET_SELECTED_SERVER]?.value,
    clientCode: this.sidebarModule[SidebarGetters.GET_SELECTED_CLIENT]?.Code})
  }

  async addRange ( value: { range: any; } ){

    await this.filtersModule[FiltersActions.ADD_ACTIVITY_FILTERS]({...value,filterValue: value.range })

  }

  async resentingFilters(){
    await this.filtersModule[FiltersActions.CLEAR_ACTIVITY_FILTERS]()
  }

  async removeFilter(value: any){

    await this.filtersModule[FiltersActions.REMOVE_ACTIVITY_FILTERS](value)

  }

  async addFilter(value: any){

    await this.filtersModule[FiltersActions.ADD_ACTIVITY_FILTERS](value)
  }

  mounted() {
    this.$root.$on('filters-applied', (segment_selection: any) => {
      this.fetchActivities(segment_selection)
    })
  }

}


