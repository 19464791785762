export enum ExportActions {
    CREATE_RAW_DATA_EXPORT ='CREATE_RAW_DATA_EXPORT',
    EXPORT_TABLE_DATA_TO_CSV = 'EXPORT_TABLE_DATA_TO_CSV',
    RESET_EXPORT_URL ='RESET_EXPORT_URL'
}

export enum ExportMutations {
    SET_RAW_DATA_EXPORT_URL= 'SET_RAW_DATA_EXPORT_URL'
}

export enum ExportGetters {
    GET_RAW_DATA_EXPORT_URL = 'GET_RAW_DATA_EXPORT_URL'
}